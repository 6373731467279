// Chakra imports
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import IconBox from "components/Icons/IconBox";
import { ClockIcon } from "components/Icons/Icons";
import { functions } from "lodash";
import React, { useEffect } from "react";
import { IoDocument, IoDocumentAttach } from "react-icons/io5";
import { RiUpload2Line } from "react-icons/ri";
import { SiDocusign } from "react-icons/si";
import { useRecoilState } from "recoil";
import { teamState } from "services/states";
import { Bucket } from "services/storage";

function NeededDoc(props) {
  const [image, setImage] = React.useState();
  const [color, setColor] = React.useState();
  const [icon, setIcon] = React.useState();
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconTeal = useColorModeValue("teal.400", "teal.400");
  const iconRed = useColorModeValue("red.400", "red.300");
  const iconGrey = useColorModeValue("gray.300", "gray.300");
  const iconPrimary = useColorModeValue("blue.200", "blue.200");
  const iconWarning = useColorModeValue("orange.300", "orange.400");

  useEffect(() => {
    if (!props.color) {
      if (props.status == "Completed") {
        setColor(iconTeal)

      }
      else if (props.status == "Pending") {
        setColor(iconWarning)
      }
      else if (props.status == "Rejected") {
        setColor(iconRed)
      }
      else if (props.status == "Denied") {
        setColor(iconRed)
      }
      else if (props.status == "Expired") {
        setColor(iconGrey)
      }
      else if (props.status == "Waiting") {
        setColor(iconGrey)
      }
      else if (props.status == "Not Started") {
        setColor(iconWarning)
      }
    } else {
      setColor(props.color)
    }

    if (!props.icon) {
      if (props.status == "Completed") {
        setIcon("check")
      }
      else if (props.status == "Pending") {
        setIcon("clock")
      }
      else if (props.status == "Rejected") {
        setIcon("upload")
      }
      else if (props.status == "Denied") {
        setIcon("upload")
      }
      else if (props.status == "Expired") {
        setIcon("document")
      }
      else if (props.status == "Waiting") {
        setIcon("clock")
      }
      else if (props.status == "Not Started") {
        setIcon("clock")
      }
    } else {
      setIcon(props.icon)
    }

  }, [props]);

  return (
    <Flex justifyContent='space-between' mb='21px' style={{ backgroundColor: "#F5F5F5", padding: 6, borderRadius: 10, paddingBottom: 10, paddingRight: 14 }}>
      <Flex align='center'>
        <IconBox style={{ marginTop: 5, marginLeft: 4 }} as='box' h={"50px"} w={"50px"} bg={color} >
          {icon == "clock" && (
            <ClockIcon h={"26px"} w={"26px"} style={{ marginLeft: 0, marginTop: 1 }} color={"white"} />
          )}
          {icon == "check" && (
            <CheckCircleIcon h={"26px"} w={"26px"} style={{ marginLeft: 0, marginTop: 1 }} color={"white"} />
          )}
          {icon == "upload" && (
            <RiUpload2Line h={"36px"} w={"36px"} style={{ marginLeft: 0, marginTop: 1 }} color={"white"} />
          )}
          {icon == "document" && (
            <SiDocusign h={"36px"} w={"36px"} style={{ marginLeft: 0, marginTop: 1 }} color={"white"} />
          )}
        </IconBox>
        <Flex direction='column' style={{ paddingLeft: 8 }}>
          <Text fontSize='sm' color={textColor} fontWeight='bold'>
            {props.name}{" "}
          </Text>

          <Text fontSize='xs' color='gray.500' fontWeight='400'>
            {props.description}
          </Text>

        </Flex>
      </Flex>
      {props.link ? (
        <Button p='0px' size="sm" width={100} mt={3} bg='grey.400' variant='no-hover' onClick={() => { }}>
          <Text
            fontSize='sm'
            fontWeight='600'
            color='white'
            alignSelf='center'>
            {props.status}
          </Text></Button>) : (<Text
            fontSize='sm'
            fontWeight='600'
            color='grey.300'
            alignSelf='center'>
            {props.status}
          </Text>)}


    </Flex>)


};

export default NeededDoc;
