// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { tablesTableData, dashboardTableData } from "variables/general";
import { set } from "lodash";
import Connectors from "./components/Connectors";
import API from "services/API";
import MyConnections from "./components/MyConnections";


function Connections() {

  const [connections, setConnections] = React.useState([]);
  const [connectors, setConnectors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    API.connectors().then((res2) => {
      setConnectors(res2.data.listConnectors.items);
      console.log("Connectors", res2);
      API.connections().then((res) => {
        console.log("Connections", res);
        setConnections(res.data.listConnections.items);
        setLoading(false);
      }).catch((err) => {
        console.log("err", err);
        setLoading(false);
      })
    })

  }, []);


  return (
    <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
      {!loading && connectors && (
        <MyConnections
          title={"My Extensions"}
          captions={["Name", "Category", "Status", "Action"]}
          data={connections}
          connectors={connectors}
        />)}
      <div style={{
        marginTop: 20
      }}>
        {!loading && connections && connectors && (
          <Connectors
            title={"Available Extensions"}
            captions={["Name", "Category", "Status", "Action"]}
            data={_.filter(connectors, (c) => { return !_.find(connections, { connectorID: c.id }) })}
          />)}
      </div>

    </Flex>
  );

}

export default Connections;
