/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import App from "App";

import '@aws-amplify/ui-react/styles.css';
import { studioTheme } from "ui-components";
import { ThemeProvider } from "@aws-amplify/ui-react";


ReactDOM.render(
  <ThemeProvider theme={studioTheme}><App /></ThemeProvider>,
  document.getElementById("root")
);
