// Chakra imports
import { Flex, Modal, Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useEffect } from "react";
import { tablesTableData, dashboardTableData } from "variables/general";
import { set } from "lodash";
import API from "services/API";
import ListPayers from "./components/ListPayers";
import Fn from "services/Fn";



function AdminPayers() {

  const [payers, setPayers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    API.payers().then((res2) => {
      console.log("payers", res2);
      setPayers(res2.data.listPayers.items);
      setLoading(false);
    })

  }, []);


  return (
    <>
      <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
        <Card style={{ marginBottom: 20 }}>
          <CardBody>
            <Button onClick={() => setShowModal(true)}>Import Payers</Button>
          </CardBody>
        </Card>

        {!loading && payers && (
          <ListPayers
            title={"Active Payers"}
            captions={["Payer", "External ID", "Action"]}
            data={payers}
            connectors={payers}
          />)}
        <div style={{
          marginTop: 20
        }}>
        </div>

      </Flex>

      <Modal isOpen={showModal} onClose={() => {
        setShowModal(false)
      }} >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Admin Import All Payers From Claim.MD</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            Are you ready to rumble?
            <div>
              <Button disabled={loading} onClick={() => {
                setLoading(true);
                Fn.InternalController("ImportPayers", { import: true }).then((res) => {
                  console.log("res", res);
                  setLoading(false);
                  setShowModal(false);
                }).catch((err) => {
                  console.log("err", err);
                  setLoading(false);
                  setShowModal(false);
                })
              }
              }>Import Payers Now</Button></div>
          </ModalBody>
          <ModalFooter>
            {loading && (
              <Spinner size="sm" color="teal" style={{ display: loading ? "inline-block" : "none" }} />
            )}
            <Button
              disabled={loading}
              variant="ghost" mr={3} onClick={() => {

                setShowModal(false)
              }}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );

}

export default AdminPayers;
