// Chakra imports
import {
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
// assets
import React, { useEffect } from "react";
import { IoAirplane, IoFilter, IoRefresh } from "react-icons/io5";
import DayStatistics from "./components/DayStatistics";
import moment from "moment";
import { FaChartBar } from "react-icons/fa";
import API from "services/API";
import Auth from "services/Auth";
import { useRecoilState } from "recoil";
import Fn from "services/Fn";
import _ from "lodash";
import { Card, useAuthenticator } from "@aws-amplify/ui-react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

export default function Dashboard() {
  const [loadingRefresh, setLoadingRefresh] = React.useState(false);
  const [laodingFakeData, setLaodingFakeData] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState();
  const [payerFilter, setPayerFilter] = React.useState();
  const [loading, setLoading] = React.useState(true);

  let incognito = localStorage.getItem("incognito") == "true" ? true : false;

  const iconBoxInside = useColorModeValue("white", "white");
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const days = [0, 1, 2, 3, 4, 5, 6];

  useEffect(() => {
    console.log("user", user);
    // setAppUser(user);
    if (!user) {
      signOut();
    }

    if (user && user.attributes && !user.attributes["custom:companyID"]) {
      console.log("user no company", user.attributes);
      window.location.href = "#/setup";
    }

    API.users().then((res) => {
      console.log("users", res);
    });

    Auth.initUser().then((res) => {
      console.log("initUser", res);
    });

    API.payerSettings().then(async (res) => {
      let settings = res.data.listPayerSettings.items;

      let filter = {
        and: [],
      };
      for (let setting of settings) {
        if (setting.status == "disabled") {
          filter.and.push({ payerID: { ne: setting.payerID } });
        }
      }
      console.log("filter", filter);
      setPayerFilter(filter);
      setLoading(false);
    });

    return () => {};
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "75px", md: "15px" }}>
      <div
        style={{
          paddingLeft: 10,
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        {/* Last 7 Days */}
      </div>
      <div style={{ paddingTop: 20, fontWeight: 900, display: "flex" }}>
        <div style={{ flex: 1 }}>Upcoming (7 Days)</div>
        <div>
          <Spinner
            style={{ marginRight: 10 }}
            hidden={!loadingRefresh}
            size="xs"
          />

          <Button
            onClick={() => {
              setShowAll(!showAll);
            }}
            disabled={loadingRefresh}
            style={{ marginLeft: 10 }}
            size="xs"
            variant={!showAll ? "ghost" : "outline"}
            colorScheme="teal"
            leftIcon={<IoFilter />}
          >
            Show All
          </Button>
          {/* <Button
            onClick={() => {
              setLoadingRefresh(true);
              API.activities().then((res) => {
                setLoadingRefresh(false);
                let x = _.groupBy(res, (y) => {
                  return moment(y.planned_date).format("MM/DD/YYYY");
                });
                console.log("activities", x);
                setActivities(x);
              })
            }}
            disabled={loadingRefresh}
            style={{ marginLeft: 10 }} size="xs" variant="ghost" colorScheme="teal" leftIcon={<IoRefresh />}>{loadingRefresh ? "Loading" : "Refresh"}</Button> */}
        </div>
        <div style={{ marginLeft: 10 }}>
          <Button
            size="xs"
            onClick={() => {
              document.getElementById("stats").scrollLeft -= 800;
              console.log(
                "scroll",
                document.getElementById("stats").scrollLeft
              );
            }}
          >
            <Icon as={ArrowBackIcon} />
          </Button>
          <Button
            size="xs"
            style={{ marginLeft: 6 }}
            onClick={() => {
              document.getElementById("stats").scrollLeft += 800;
              console.log(
                "scroll",
                document.getElementById("stats").scrollLeft
              );
            }}
          >
            <Icon as={ArrowForwardIcon} />
          </Button>
        </div>
      </div>

      <div
        style={{
          width: "calc(100%)",
          overflowX: "scroll",
          scrollBehavior: "smooth",
        }}
        id="stats"
      >
        <div
          style={{
            width: 2000,
            display: "flex",
            marginTop: 8,
            marginBottom: 40,
          }}
        >
          {!loading &&
            days.map((x) => {
              return (
                <DayStatistics
                  key={x}
                  incognito={incognito}
                  selected={selectedDay == x}
                  showWorkbench={(z) => {
                    if (z == true) {
                      setSelectedDay(x);
                    } else {
                      setSelectedDay(selectedDay == x ? null : x);
                    }
                  }}
                  title={moment().add(x, "days").format("dddd MM/D")}
                  day={moment().add(x, "days").format("YYYY-MM-DD")}
                  showAll={showAll}
                  refreshActivities={() => {}}
                  filter={payerFilter}
                />
              );
            })}
        </div>
      </div>

      <div style={{ width: 400 }}>
        {process.env.REACT_APP_ENV_NAME != "production" && (
          <Button
            disabled={loadingRefresh}
            onClick={() => {
              setLoadingRefresh(true);
              Fn.addMockingData().then((res) => {
                console.log(
                  "addMockingData",
                  JSON.parse(res.data.addMockingData)
                );
                window.location.reload();
              });
            }}
            style={{ marginLeft: 10 }}
            size="xs"
            variant="outline"
            colorScheme="teal"
            leftIcon={<FaChartBar />}
          >
            {laodingFakeData ? "Loading..." : "Generate Fake Data"}
          </Button>
        )}
      </div>
    </Flex>
  );
}
