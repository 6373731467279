import React from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertFromRaw,
} from "draft-js";
import { FaBold, FaItalic, FaUnderline } from "react-icons/fa";
import { RiListOrdered, RiListUnordered } from "react-icons/ri";

export default function ValidEditor({
  label,
  value,
  placeholder,
  description,
  onChange = () => {},
}) {
  const [editorState, setEditorState] = React.useState(
    value || EditorState.createEmpty()
  );

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const onItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const onUnderlineClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const onToggleUnorderedList = () => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, "unordered-list-item")
    );
  };

  const onToggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #eee",
          paddingBottom: 4,
        }}
      >
        <div
          style={{
            cursor: "pointer",
            marginRight: "4px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: editorState.getCurrentInlineStyle().has("BOLD")
              ? "#ccc"
              : "transparent",
          }}
          onClick={onBoldClick}
        >
          <FaBold />
        </div>
        <div
          style={{
            cursor: "pointer",
            marginRight: "4px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: editorState.getCurrentInlineStyle().has("ITALIC")
              ? "#ccc"
              : "transparent",
          }}
          onClick={onItalicClick}
        >
          <FaItalic />
        </div>
        <div
          style={{
            cursor: "pointer",
            marginRight: "4px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: editorState
              .getCurrentInlineStyle()
              .has("UNDERLINE")
              ? "#ccc"
              : "transparent",
          }}
          onClick={onUnderlineClick}
        >
          <FaUnderline />
        </div>
        <div
          style={{
            cursor: "pointer",
            marginRight: "4px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: editorState
              .getCurrentInlineStyle()
              .has("unordered-list-item")
              ? "#ccc"
              : "transparent",
          }}
          onClick={onToggleUnorderedList}
        >
          <RiListUnordered />
        </div>
        <div
          style={{
            cursor: "pointer",
            marginRight: "4px",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: editorState
              .getCurrentInlineStyle()
              .has("ordered-list-item")
              ? "#ccc"
              : "transparent",
          }}
          onClick={() => onToggleBlockType("ordered-list-item")}
        >
          <RiListOrdered />
        </div>
      </div>

      <div style={{ fontSize: 14, paddingTop: 6, height: "100%" }}>
        <Editor
          placeholder={placeholder}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={(e) => {
            setEditorState(e);
            onChange(e);
          }}
        />
      </div>
    </>
  );
}

export function ValidViewer({ value }) {
  let editorState;

  if (value) {
    // Assuming 'value' is a raw Draft.js content state
    // You might need additional checks or conversions depending on how 'value' is provided
    editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(value))
    );
  } else {
    // If no value provided, create an empty editor state
    editorState = EditorState.createEmpty();
  }

  return (
    <div
      style={{ padding: "10px", border: "1px solid #ddd", minHeight: "100px" }}
    >
      <Editor
        editorState={editorState}
        readOnly={true}
        style={{ height: "100%" }}
      />
    </div>
  );
}
