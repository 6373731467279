// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "views/Dashboard/Activities";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import { BsBook, BsBookFill, BsBuildings, BsBuildingsFill, BsCardList, BsGearFill, BsPeopleFill } from "react-icons/bs";
import Connections from "views/Dashboard/Connections";
import Clients from "views/Dashboard/Clients";
import Activity from "views/Dashboard/Activity";
import Docs from "views/Dashboard/Docs";
import Payers from "views/Dashboard/Payers";
import AdminPayers from "views/Dashboard/AdminPayers";
import AdminCompanies from "views/Dashboard/AdminCompanies";
import AdminCompany from "views/Dashboard/AdminCompany";
import { RiComputerFill } from "react-icons/ri";
import Contacts from "views/Crm/Contacts";




var dashRoutes = [
  {
    path: "/contacts",
    name: "Contacts",
    rtlName: "",
    icon: <RiComputerFill color="inherit" />,
    component: Contacts,
    layout: "/crm",
  },
  {
    path: "/admin-company",
    name: "Company",
    rtlName: "",
    icon: <BsBuildings color="inherit" />,
    component: AdminCompany,
    layout: "/crm",
    visible: false,
  },
  {
    path: "/admin-companies",
    name: "Companies",
    rtlName: "",
    icon: <BsBuildings color="inherit" />,
    component: AdminCompanies,
    layout: "/crm",
    visible: true,
  },
  {
    path: "/signin",
    name: "Sign In",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/signup",
    name: "Sign Up",
    rtlName: "",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    visible: false,
  },

  {
    name: "ADMIN",
    category: "global-admin",
    rtlName: "",
    state: "pageCollapse",
    globalAdminOnly: true,
    views: [
      {
        path: "/admin-payers",
        name: "Payers List",
        rtlName: "",
        icon: <BsCardList color="inherit" />,
        component: AdminPayers,
        layout: "/crm",
        visible: true,
      },
    ],
  },
];
export default dashRoutes;
