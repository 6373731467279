import React, { useEffect } from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
  Badge,
  Avatar,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import moment from "moment";
import { Bucket } from "services/storage";
import { JsonView, darkStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

function TablesLogRow(props) {
  const { log } = props;
  const [showLogDetails, setShowLogDetails] = React.useState(false);


  let textColor = useColorModeValue("gray.700", "white");
  let statusTextColor = useColorModeValue("gray.700", "white");
  let statusColor = useColorModeValue("gray.400", "gray.400");

  if (log.status === "verified") {
    statusColor = useColorModeValue("teal.400", "teal.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (log.status === "pending") {
    statusColor = useColorModeValue("yellow.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (log.status === "rejected") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (log.status === "denied") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (log.status === "error") {
    statusColor = useColorModeValue("black", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (log.status === "declined") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (log.status === "created") {
    statusColor = useColorModeValue("blue.500", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  }

  const incognito = localStorage.getItem("incognito") === "true" ? true : false;

  const exampleJSON = log.status === "created" ? {
    firstname: [
      "Firstname"
    ],
    $: {
      msdata: "518",
      diffgr: "Table519"
    },
    clientins_id: [
      "1234"
    ],
    startdate: [
      "2000-01-01T01:00:00-05:00"
    ],
    client_id: [
      "1234"
    ],
    lastname: [
      "Lastname"
    ],
    payer_code: [
      "CODE"
    ],
    inactive: [
      "false"
    ],
    deleted: [
      "false"
    ],
    payer_description: [
      "EXAMPLE HEALTHCARE"
    ],
    dob: [
      "1900-01-01T00:00:00-05:00"
    ],
    payer_organization_id: [
      "12345"
    ],
    billing_ord: [
      "1"
    ],
    payer_id: [
      "12"
    ],
    ins_id: [
      "123456789"
    ],
    payer_name: [
      "EXAMPLE HEALTHCARE"
    ],
    plan_id: [
      "123456"
    ],
    start_date: [
      "2000-01-01T00:00:00-05:00"
    ]
  } : log.status === "error" ?
    {
      "error": {
        "error_mesg": "Error message",
        "error_code": "404"
      }
    } : log.status === "verified" ? {
      "status": "verified",
    } : {
      "error": {
        "error_mesg": "Rejected message",
        "error_code": "404"
      }
    };


  return (
    <>
      <Tr onClick={() => {
        setShowLogDetails(!showLogDetails)
      }}>
        <Td minWidth={{ sm: "100px" }} pl="0px">
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                minWidth="100%"

              >
                {log.title}
              </Text>
              <Text fontSize="sm" color="grey">
                ID#: {log.id}
              </Text>
              <Text color="grey" fontWeight="bold"
              >
                {log.note}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td >
          <Text fontSize="sm" color={textColor} fontWeight="bold" pb="1rem">
            <div>{moment(log.createdAt).format("h:mm:ss a")}</div>
            <div>{moment(log.createdAt).format("MMM Do YYYY")}</div>

          </Text>
        </Td>
        <Td >
          <Badge
            bg={statusColor}
            color={statusTextColor}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {log.status}
          </Badge>
        </Td>

        {/* <Td>
        <Button p="0px" bg="transparent">
          <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
        </Button>
      </Td> */}
      </Tr>
      {showLogDetails && (
        <Tr>
          <Td pl="0px">
            <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
              <div style={{ borderRadius: 8, overflow: "hidden", padding: 10, backgroundColor: "#EEEEEE", width: "100%" }}>
                <JsonView data={incognito ? exampleJSON : JSON.parse(log.data)} />
              </div>
            </Flex>
          </Td>
        </Tr>)}
    </>
  );
}

export default TablesLogRow;
