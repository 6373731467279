// Chakra imports
import { Box, Flex, Grid, Icon } from "@chakra-ui/react";
// Assets
import BackgroundCard1 from "assets/img/BackgroundCard1.png";
import { MastercardIcon, VisaIcon } from "components/Icons/Icons";
import React from "react";
import { FaPaypal, FaWallet } from "react-icons/fa";
import { RiMastercardFill } from "react-icons/ri";
import {
  billingData,
  invoicesData,
  newestTransactions,
  olderTransactions,
} from "variables/general";
import BillingInformation from "./components/BillingInformation";
import CreditCard from "./components/CreditCard";
import Invoices from "./components/Invoices";
import PaymentMethod from "./components/PaymentMethod";
import PaymentStatistics from "./components/PaymentStatistics";
import Transactions from "./components/Transactions";


function Billing() {



  return (
    <Flex direction='column' pt={{ sm: "75px", md: "15px" }}>
      <Grid templateColumns={{ sm: "1fr", lg: "2fr 1.2fr" }} templateRows='1fr'>

        <BillingInformation title={"Billing Information"} data={billingData} />
      </Grid>
      {/* <Grid templateColumns={{ sm: "1fr", lg: "1.6fr 1.2fr" }}>

        <Invoices title={"Invoices"} data={invoicesData} />
      </Grid> */}
    </Flex>
  );
}

export default Billing;
