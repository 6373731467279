/*eslint-disable*/
import React from "react";
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";


export default function Footer(props) {
  // const linkTeal = useColorModeValue("teal.400", "red.200");=
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "end",
        xl: "end",
      }}
      justifyContent="end"
      alignSelf="end"
      px="30px"
      pb="20px"
    >   <div>
        <Text
          color="gray.400"
          textAlign={{
            base: "end",
            xl: "end",
          }}
          mb={{ base: "0px", xl: "0px" }}
        >
          &copy; {1900 + new Date().getYear()}{" "}

          <Link
            // color={linkTeal}
            color="teal.400"
            href="https://valid.care"
            target="_blank"
          >
            {document.documentElement.dir === "rtl"
              ? ""
              : "Valid."}
          </Link>

        </Text>
        <div style={{ fontSize: "small", color: "gray.400", fontWeight: 900, marginTop: -6 }}>
          {process.env.REACT_APP_ENV_NAME} - app:{process.env.AWS_APP_ID}
        </div>
      </div>
    </Flex>
  );
}
