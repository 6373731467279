import {
  Avatar,
  Badge,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { set } from "lodash";
import React, { useEffect } from "react";
import Fn from "services/Fn";
import Store from "services/Store";
import { Bucket } from "services/storage";
import ValidChip from "ui-components/ValidChip";
import ValidInput from "ui-components/ValidInput";

function AdminPayerRow(props) {
  const { data } = props;
  const [logoUrl, setLogoUrl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputsList, setInputsList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(data.status);


  const onClose = () => {
    setIsOpen(false)
  };
  const onOpen = () => setIsOpen(true);


  const textColor = useColorModeValue("gray.700", "white");
  let bgStatus = useColorModeValue("gray.400", "#1a202c");
  let colorStatus = useColorModeValue("white", "gray.400");



  return (
    <Tr key={data?.id} onClick={() => {
      onOpen();
    }}>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {logoUrl && (
            <Avatar style={{ backgroundColor: "white" }} src={logoUrl} w="50px" h="50px" borderRadius="8px" me="18px" />
          )}
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {data?.name}
            </Text>

          </Flex>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color="gray.400" fontWeight="bold">
          {data?.external_id}
        </Text>
      </Td>
      <Td>
        <Badge
          bg={status.toLowerCase() === "active" ? "green.400" : bgStatus}
          color={status.toLowerCase() === "active" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>

      </Td>

      <Td>
        <Button p="0px" bg="transparent" variant="no-hover"

        >
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"

          >
            View
          </Text>
        </Button>
      </Td>
      <Modal isOpen={isOpen} onClose={onClose}  >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>{data?.name}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <div >
              <div>
                <div style={{ fontSize: 24, fontWeight: 700 }}>Connections</div>
              </div>
              <div>
                <div style={{ fontSize: 24, fontWeight: 700 }}>Set Status</div>
                {/* Drop down with status  */}
                <select value={status} style={{ width: "100%", height: 40, borderRadius: 8, border: "1px solid #ccc", padding: 8, marginTop: 8 }} onChange={(e) => {
                  setStatus(e.target.value)
                }}>
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="inactive">Inactive</option>
                  <option value="suspended">Suspended</option>
                  <option value="disabled">Disabled</option>
                </select>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>

            <Spinner size="sm" color="teal" style={{ display: loading ? "inline-block" : "none" }} />


            <Button variant="ghost" colorScheme="grey" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="teal" onClick={() => {
              setLoading(true);
              Fn.InternalController("AdminPayerSetting", { status: status, id: data.id }).then(res => {
                setLoading(false);
                onClose();
              })
            }
            }>
              Save
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </Tr >
  );
}

export default AdminPayerRow;
