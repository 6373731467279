import { DataStore } from "@aws-amplify/datastore";
import { Amplify, API as AWSAPI } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import React from "react";
import { listActivities, listConnectors, listPayers, listUsers } from "../graphql/queries";
import API from "./API";
import Fn from "./Fn";




const Auth = {
    signOut() {
        return Amplify.Auth.signOut();
    },
    async initUser() {
        const user = await this.me();
        // console.log("user", user);
        const users = (await API.users()).data.listUsers.items;
        const userExists = users.find(u => u.id === user.attributes.sub);
        console.log("userExists", userExists);
        if (userExists && userExists.active == null) {
            console.log("activate user");
            await Fn.InternalController("ActivateUser", userExists);
        }
        if (!userExists) {
            // return await this.createUser(user).then((res) => {
            //     console.log("createUser", res);
            //     return res.data.createUser;
            // });

        } else {
            return userExists;
        }
    },
    async me() {
        return Amplify.Auth.currentAuthenticatedUser();
    },
    async createUser(user) {

        // return await Fn.InternalController("createUser", {
        //     id: user.attributes.sub,
        //     image: user.attributes.picture,
        //     name: user.attributes.name,
        //     email: user.attributes.email,
        //     auth_id: user.attributes.sub,
        // });
    }
}

export default Auth;