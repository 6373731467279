// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "views/Dashboard/Activities";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import { BsBook, BsBookFill, BsBuildings, BsBuildingsFill, BsCardList, BsGearFill, BsPeopleFill } from "react-icons/bs";
import Connections from "views/Dashboard/Connections";
import Clients from "views/Dashboard/Clients";
import Activity from "views/Dashboard/Activity";
import Docs from "views/Dashboard/Docs";
import Payers from "views/Dashboard/Payers";
import AdminPayers from "views/Dashboard/AdminPayers";
import AdminCompanies from "views/Dashboard/AdminCompanies";
import AdminCompany from "views/Dashboard/AdminCompany";
import { RiComputerFill } from "react-icons/ri";




var dashRoutes = [
  {
    path: "/dashboard",
    name: "Workbench",
    rtlName: "",
    icon: <RiComputerFill color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/activities",
    name: "Activity",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/activity/:id",
    name: "Activity",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: Activity,
    layout: "/admin",
    visible: false,
  },


  {
    path: "/signin",
    name: "Sign In",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    visible: false,
  },
  {
    path: "/signup",
    name: "Sign Up",
    rtlName: "",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    visible: false,
  },

  {
    name: "ADMIN",
    category: "account",
    rtlName: "",
    state: "pageCollapse",
    views: [


      {
        path: "/extensions",
        name: "Extensions",
        rtlName: "",
        icon: <BsGearFill color="inherit" />,
        component: Connections,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/payers",
        name: "Payers",
        rtlName: "",
        icon: <BsCardList color="inherit" />,
        component: Payers,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/profile",
        name: "Profile",
        rtlName: "",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/billing",
        name: "Billing",
        rtlName: "",
        icon: <CreditIcon color="inherit" />,
        component: Billing,
        layout: "/admin",
        visible: false,
      },
      {
        path: "/docs",
        name: "Docs",
        rtlName: "",
        icon: <BsBook color="inherit" />,
        component: Docs,
        layout: "/admin",
        visible: false,
      },
    ],
  },

];
export default dashRoutes;
