import React from "react"

const imagesBucket = "images"


export const Bucket = {
    async uploadUserProfile(event) {
        const file = event.target.files[0]
        const fileExt = file.name.split('.').pop()
        return null
    },
    async getUrl(path) {
        let url = localStorage.getItem(path);
        if (url == null || url == undefined || url == "" || url == "null" || url == "undefined" || url == "[object Object]" || url == "UNDEFINED") {
            url = null
            localStorage.setItem(path, url)
        }
        return url
    }
}
