import { DataStore } from "@aws-amplify/datastore";
import { Amplify, API as AWSAPI } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

import React from "react";
import {
  listActivities,
  listConnectors,
  listPayers,
  listUsers,
  listCompanies,
  listRequirements,
  listCompaniesConnectors,
  listConnections,
  listActivityLogs,
  listPayerSettings,
  getActivities,
  activityLogsByActivity,
  activitiesByPlanned_day,
  getPayerSetting,
} from "../graphql/queries";
import { activitiesByPlanned_dayExt } from "./queries";

const now = new Date();
const planned_date_after = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() - 1
);
const planned_date_before = new Date(
  now.getFullYear(),
  now.getMonth(),
  now.getDate() + 8
);

const API = {
  async activities() {
    let allActivities = [];
    let nextToken = null;

    do {
      const response = await AWSAPI.graphql({
        query: listActivities,
        variables: {
          filter: {
            and: [
              {
                planned_date: {
                  ge: planned_date_after.toISOString(),
                },
              },
              {
                planned_date: {
                  le: planned_date_before.toISOString(),
                },
              },
            ],
          },
          nextToken: nextToken, // Pass the nextToken for pagination
        },
      });

      allActivities = allActivities.concat(response.data.listActivities.items);
      nextToken = response.data.listActivities.nextToken;
    } while (nextToken);

    return allActivities;
  },
  async activitiesByDay(day, filter = null) {
    // index planned_date-index

    // let z = await this.payerSettings().then(async (res) => {
    //     let settings = res.data.listPayerSettings.items;

    //     let filter = {
    //       and: [],
    //     };
    //     for (let setting of settings) {
    //       if (setting.status == "disabled") {
    //         filter.and.push({ payerID: { ne: setting.payerID } });
    //       }
    //     }
    //     return filter;
    //     console.log("filter", filter);
    //   });

    //filter payerID not null
    let x = await AWSAPI.graphql({
      query: activitiesByPlanned_dayExt,
      variables: {
        planned_day: day,
        filter: filter && filter.and && filter.and[0] ? filter : null,
      },
    });

    return x.data.activitiesByPlanned_day.items;
  },
  async activity(id) {
    return await AWSAPI.graphql({
      query: getActivities,
      variables: {
        id: id,
      },
    });
  },
  async activity_log(id) {
    let logs = await AWSAPI.graphql({
      query: activityLogsByActivity,
      variables: {
        activity: id,
      },
    });

    return logs.data.activityLogsByActivity.items;
  },
  async payers() {
    return await AWSAPI.graphql({
      query: listPayers,
      variables: {
        limit: 1000,
      },
    });
  },
  async payerSettings() {
    return await AWSAPI.graphql({ query: listPayerSettings });
  },
  async users() {
    return await AWSAPI.graphql({ query: listUsers });
  },
  async connectors() {
    return await AWSAPI.graphql({ query: listConnectors });
  },
  async connector(id) {
    return await AWSAPI.graphql({
      query: listConnectors,
      variables: {
        filter: {
          id: {
            eq: id,
          },
        },
      },
    });
  },
  async connections() {
    return await AWSAPI.graphql({ query: listConnections });
  },
  async connection(id) {
    return await AWSAPI.graphql({
      query: listConnections,
      variables: {
        filter: {
          id: {
            eq: id,
          },
        },
      },
    });
  },
  async companies() {
    return await AWSAPI.graphql({ query: listCompanies });
  },
  async requirements() {
    return await AWSAPI.graphql({ query: listRequirements });
  },
  async createActivity(input) {
    return await AWSAPI.graphql({
      query: createActivity,
      variables: {
        input: input,
      },
    });
  },
};

export default API;
