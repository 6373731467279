import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Editor } from "draft-js";
import React from "react";
import { ValidViewer } from "ui-components/ValidEditor";


export default function PayerModal({ isOpen, onClose, title, note }) {

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='lg'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader> {title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {note && (<ValidViewer value={note} />)}
                </ModalBody>
            </ModalContent>
        </Modal>
    )

}