import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import CrmLayout from "layouts/Crm.js";
import SignIn from "views/Auth/SignIn";
import SignUp from "views/Auth/SignUp";
import { RecoilRoot } from "recoil";

import { Authenticator, Button, Heading, Image, Text, View, useAuthenticator, useTheme, withAuthenticator } from '@aws-amplify/ui-react';

import { Amplify, Auth, Hub, Logger } from "aws-amplify";
import awsmobile from "./aws-exports";
import { auth_listener } from "services/listeners";


//image
import logo from "assets/img/valid_black.png";
import Setup from "views/Setup";

// Amplify.configure(awsmobile);

Amplify.configure({
    ...awsmobile,
    // https://github.com/aws-amplify/amplify-cli/issues/3794
    graphql_headers: async () => {
        try {
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            return { Authorization: token };
        } catch (e) {
            console.error(e);
            return {};
        }
    },
});

window.addEventListener('error', function (e) {
    if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
        console.log(e)
        // prevent React's listener from firing
        e.stopImmediatePropagation();
        // prevent the browser's console error message 
        e.preventDefault();
    }
});


// check if user is still logged in on cognito


Hub.listen('auth', auth_listener);


const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image
                    alt="Valid logo"
                    src={logo}
                    style={{ width: 200 }}
                />
            </View>
        );
    },
    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; All Rights Reserved
                </Text>
            </View>
        );
    },
    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Sign in to your account
                </Heading>
            );
        },

    },

    SignUp: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Create a new account
                </Heading>
            );
        },
        Footer() {
            const { toSignIn } = useAuthenticator();

            return (
                <View textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toSignIn}
                        size="small"
                        variation="link"
                    >
                        Back to Sign In
                    </Button>
                </View>
            );
        },
    },
    ConfirmSignUp: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },

    },
    SetupTOTP: {
        Header() {
            const { tokens } = useTheme();
            return (
                <div

                    level={3}
                >
                    <div style={{ fontSize: 32, fontWeight: "bold" }}>MFA Setup</div>
                    <div style={{ fontSize: 14 }}>
                        Strong device based MFA is required for all users. Please setup your MFA device.</div>
                    <a style={{ fontWeight: "bold" }} target="_blank" href="https://technical-hoverfly.super.site/setting-up-mfa-app">Learn How to Setup MFA</a>
                </div>
            );
        },

    },
    ConfirmSignIn: {
        Header() {
            const { tokens } = useTheme();
            return (
                <div

                    level={3}
                >
                    <div style={{ fontSize: 32, fontWeight: "bold" }}>Enter MFA Code</div>
                    <div style={{ fontSize: 14 }}>
                        Strong device based MFA is required for all users. If you need help resetting this please contact support@valid.care.</div>

                </div>
            );
        },

    },
    ForgotPassword: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },

    },
    ConfirmResetPassword: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },

    },
};

const formFields = {
    setupTOTP: {
        QR: {
            totpIssuer: 'Valid',
        },
        confirmation_code: {
            label: 'Verification Code',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: true,
        },
    },
};

// const formFields = {
//     setupTotp: {
//         QR: {
//             totpIssuer: 'Valid',
//             totpUsername: (user) => user.email
//         },
//     },
// }

function App() {
    return (
        <Authenticator formFields={formFields} components={components} hideSignUp={true} >
            {({ signOut, user }) => (
                <RecoilRoot>
                    <HashRouter>
                        <Switch>
                            <Route path={`/auth`} component={AuthLayout} />
                            <Route path={`/admin`} component={AdminLayout} />
                            <Route path={`/setup`} component={Setup} />
                            <Route path={`/crm`} component={CrmLayout} />
                            <Route path={`/rtl`} component={RTLLayout} />
                            <Redirect from={`/`} to="/admin/dashboard" />
                        </Switch>
                    </HashRouter>
                </RecoilRoot>
            )}
        </Authenticator>)
}
export default App;


