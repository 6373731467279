import { DataStore } from "@aws-amplify/datastore";
import { Amplify, API as AWSAPI } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import React from "react";
import { addMockingData as query_addMockingData, listActivities, listConnectors, listPayers, listUsers } from "../graphql/queries";
import { addMockingData as mutation_addMockingData, InternalController as mutation_InternalController } from "graphql/mutations";





const Fn = {
    async addMockingData() {
        return AWSAPI.graphql({
            query: mutation_addMockingData, variables: {
                input: { name: "jon" }
            }
        }).then((res) => {
            return res;
        });
    },
    async InternalController(route, payload) {
        return await AWSAPI.graphql({
            query: mutation_InternalController,
            variables: {
                input: {
                    route: route,
                    data: JSON.stringify(payload)
                }
            }
        }).then((res) => {
            return JSON.parse(res.data.InternalController);
        });
    },
}

export default Fn;