// Chakra imports
import { Flex, Modal, Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { tablesTableData, dashboardTableData } from "variables/general";
import { set } from "lodash";
import API from "services/API";
import ListPayers from "./components/ListPayers";
import Fn from "services/Fn";
import ListPayersAdd from "./components/ListPayersAdd";



function Payers() {

  const [payers, setPayers] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [payerSettings, setPayerSettings] = React.useState([]);

  useEffect(() => {
    API.payerSettings().then((res2) => {
      console.log("payers", res2);
      setPayerSettings(res2.data.listPayerSettings.items);
      setLoading(false);
    })

  }, []);


  return (
    <>
      <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <div style={{ margin: 4 }} >
            <Button
              size="xs"
              variant="outline"
              colorScheme="green"
              onClick={() => {
                console.log("payers 1", payers);
                if (!payers) {
                  API.payers().then((res2) => {
                    console.log("payers list", res2);
                    setPayers(res2.data.listPayers.items);
                    setLoading(false);
                  })
                }
                setShowModal(true)
              }}
            >Add Payers</Button>
          </div>
        </div>
        {!loading && payerSettings && (
          <ListPayers
            title={"Active Payers"}
            captions={["Payer", "External ID", "Status", "Action"]}
            data={payerSettings}
            connectors={payerSettings}
          />)}
        <div style={{
          marginTop: 20
        }}>
        </div>

      </Flex>

      <Modal isOpen={showModal} onClose={() => {
        setShowModal(false)
      }} size={"full"}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>
            Add Payers


          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {payers && (
              <ListPayersAdd
                title={"Active Payers"}
                captions={["Payer", "External ID", "Action"]}
                data={payers}
                connectors={payers}
              />)
            }
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={() => {
              setShowModal(false)
            }}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );

}

export default Payers;
