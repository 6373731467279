// Chakra imports
import { Button, Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import ProfileInformation from "./components/ProfileInformation";
import { useRecoilState } from "recoil";
import { companyState } from "services/states";
import { Bucket } from "services/storage";
import { profileState } from "services/states";
import { profileImageState } from "services/states";
import Team from "./components/Team";
import BillingInformation from "../Billing/components/BillingInformation";
import { billingData } from "variables/general";
import NeededDocs from "./components/NeededDocs";
import Auth from "services/Auth";
import API from "services/API";
import { useAuthenticator } from "@aws-amplify/ui-react";


function Profile() {
  const [company, setCompany] = useRecoilState(companyState);
  const [profileImage, setProfileImage] = useRecoilState(profileImageState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [user, setUser] = useState();

  useEffect(() => {

    if (user) {
      setProfileImage(user?.profileImage);
      setProfile(user);
    } else {
      Auth.initUser().then((res) => {
        console.log("me", res);
        setUser(res);
        if (res && res.profileImage) {
          setProfileImage(res.profileImage);
        }
        setProfile(res);
      })
    }

    if (!company || !company.name) {
      API.companies().then((res) => {
        console.log("companies", res.data);
        setCompany(res.data.listCompanies.items[0]);
      }
      )
    }
  }
    , []);

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );



  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={profileImage}
        name={profile?.name}
        subtitle={user?.email}

      />
      <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap='22px'>
        {/* <PlatformSettings
          title={"Notifications"}
          subtitle1={"ACCOUNT"}
          subtitle2={"COMPANY"}
        /> */}
        {company && company.name && (
          <ProfileInformation
            title={"Company Info"}
            name={company.name}
            primary_phone={company?.primary_phone}
            address={company?.address}
            city={company?.city}
            state={company?.state}
            postal_code={company?.postal_code}
            image={company && company.logo && Bucket.getUrl(company.logo)}
            npi={company?.npi}
            tax_id={company?.tax_id}
          />)}
        <Team title={"Team Members"} />
        <NeededDocs title={"Setup"} />
        {/* <BillingInformation title={"Billing Information"} data={billingData} /> */}
      </Grid>
      <div style={{ textAlign: "right" }}>
        <div style={{ paddingTop: 30 }}>
          <Button onClick={() => {
            Auth.signOut({ global: true });
          }}> Log Out</Button>
        </div>
      </div>
    </Flex >
  );
}

export default Profile;
