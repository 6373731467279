import { Input, Text } from "@chakra-ui/react";
import React from "react";

export default function ValidInput({
  label,
  value,
  placeholder,
  description,
  onChange = () => {},
}) {
  return (
    <div style={{ paddingBottom: 10 }}>
      <Text fontSize="md" fontWeight="bold" me="10px" minWidth={100}>
        {label && label + ": "}
      </Text>
      {description && (
        <Text fontSize="sm" fontWeight="400" color="gray.600">
          {description}
        </Text>
      )}
      <Input
        fontSize="md"
        placeholder={placeholder ? placeholder : "Enter " + label}
        color="gray.800"
        fontWeight="400"
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
      />
    </div>
  );
}
