// Chakra imports
import {
  Flex,
  Spinner,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import React from "react";

const Activities = ({ title, captions, data, payerSettings }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const incognito = localStorage.getItem("incognito") === "true" ? true : false;

  return (
    <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Flex direction='column' flex={1}>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
            {!data || !Array.isArray(data) && (
              <Spinner size="sm" speed="1.2s" style={{ marginLeft: 20 }} />
            )}
          </Text>

        </Flex>
        <Flex direction={"column"} style={{ textAlign: "right" }}>
          <Text fontSize='md' color={textColor} fontWeight='bold'>
            <span style={{ backgroundColor: incognito ? "black" : "unset", color: incognito ? "black" : "unset" }}>{data?.length}</span> Showing
          </Text>
          <div style={{ marginTop: -6, opacity: .5, fontSize: 14 }}></div>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data && Array.isArray(data) && data.map((row) => {
              // let payer = payerSettings.find(x => x.external_id == row.payerID)

              return (
                <TablesProjectRow
                  key={row.id}
                  id={row.id}
                  note={row.note}
                  name={row.lastname}
                  logo={row?.payerID?.logo}
                  status={row.status}
                  date={row.createdAt}
                  planned_date={row.planned_date}
                  provider={row?.origin_system_payer_name}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default Activities;
