// Chakra imports
import { Flex, Skeleton, Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Activities from "./components/Activities";
import API from "services/API";
import { useRecoilState } from "recoil";
import { activitiesState } from "services/states";

function Tables() {
  const [activities, setActivities] = useRecoilState(activitiesState);
  const [payerSettings, setPayerSettings] = React.useState([]);

  useEffect(() => {


    API.payerSettings().then((res2) => {
      console.log("payers", res2.data.listPayerSettings.items);
      setPayerSettings(res2.data.listPayerSettings.items);
      API.activities().then((res) => {
        let x = _.orderBy(res, "createdAt", "desc");
        console.log("activities", x);
        setActivities(x);
      })
    })


    return () => {
    }
  }
    , []);


  return (
    <Flex direction='column' pt={{ base: "75px", md: "15px" }}>


      <Activities
        title={"Activity"}
        captions={["Activity", "Created Date", "Planned Date", "Status"]}
        data={activities}
        payerSettings={payerSettings}
      />
    </Flex>
  );
}

export default Tables;
