// Chakra imports
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  Avatar,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Fn from "services/Fn";
import Store from "services/Store";

function TeamMember(props) {
  const [image, setImage] = React.useState();
  const [shouldDisable, setShouldDisable] = React.useState(false);
  const [memberRemoved, setMemberRemoved] = React.useState(false);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const { user, signOut } = useAuthenticator((context) => [context.user]);



  useEffect(() => {
    if (props.image) {
      Store.imageUrl(props.image).then((res) => {
        setImage(res);
      })
    }

  }, []);

  if (memberRemoved) return null;

  return (<Flex justifyContent='space-between' mb='21px'>

    <Flex align='center'>

      <Avatar
        src={image}
        w='50px'
        h='50px'
        borderRadius='15px'
        me='10px'
      />
      <Flex direction='column'>
        <Text fontSize='sm' color={textColor} fontWeight='bold'>
          {props.name}
        </Text>
        <Text fontSize='xs' color={props.active ? 'gray.800' : 'gray.500'} fontWeight='500'>
          {props.active ? "Active" : "Pending"}
        </Text>
        <Text fontSize='xs' color='gray.500' fontWeight='400'>
          {props.email}
        </Text>

      </Flex>
    </Flex>
    <div>
      <div>
        {user && user.username != props.id && (
          <Button p='0px' bg='transparent' variant='no-hover' color={shouldDisable ? "red" : "black"} ba>
            <Text
              fontSize='sm'
              fontWeight='600'
              color='grey.300'
              alignSelf='center'
              onClick={() => {
                setShouldDisable(true);
              }}
            >
              {shouldDisable ? "Are you sure? " : "Disable Account"}
            </Text>
          </Button>)}</div>
      {shouldDisable && (
        <div>
          <Button p='0px' bg='transparent' variant='no-hover' color="red" ba>
            <Text fontSize='sm' fontWeight='600' color='red' alignSelf='center' onClick={() => {
              Fn.InternalController("DisableUser", { id: props.id }).then((res) => {
                console.log("res", res);
                setShouldDisable(false);
                setMemberRemoved(true);
              })
            }
            }>
              Yes
            </Text>
          </Button>
          <Button p='0px' bg='transparent' variant='no-hover' color="red" ba>
            <Text fontSize='sm' fontWeight='600' color='black' alignSelf='center' onClick={() => {

              setShouldDisable(false);
            }
            }>
              No
            </Text>
          </Button>
        </div>
      )}
    </div>
  </Flex>)


};

export default TeamMember;
