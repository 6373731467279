// Chakra imports
import { Flex, Modal, Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, Table, Thead, Th, Tr, Td } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useEffect } from "react";
import { tablesTableData, dashboardTableData } from "variables/general";
import { set } from "lodash";
import API from "services/API";
import Fn from "services/Fn";
import { Badge } from "@aws-amplify/ui-react";
import AdminPayerRow from "./components/AdminPayerRow";



function AdminCompany() {

  const [adminCompany, setAdminCompany] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [payers, setPayers] = React.useState([]);
  const [showPayers, setShowPayers] = React.useState(false);

  useEffect(() => {
    //  get company id from url
    const url = window.location.href;
    const urlArray = url.split('/');
    const companyId = urlArray[urlArray.length - 1];


    Fn.InternalController("GetCompanies", {}).then((res2) => {
      let comp = res2.filter((c) => c.id === companyId);
      setAdminCompany(comp[0]);
      setLoading(false);
    })
    Fn.InternalController("GetPayerSettingsByCompanyID", { companyID: companyId }).then((res2) => {
      console.log("payers", res2);
      setPayers(res2);
      setLoading(false);
    })
  }, []);


  return (
    <>
      <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
        <Card style={{ marginBottom: 10 }}>
          <CardBody>
            <div style={{ width: "100%" }}>
              <div>
                <Text fontWeight="bold" fontSize="x-large">{adminCompany?.name}</Text>
              </div>
              <div style={{ fontSize: 14, display: "flex", width: "100%" }}>
                <div style={{ flex: 1 }}>
                  <Text>{adminCompany?.address}</Text>
                  <Text>{adminCompany?.city}, {adminCompany?.state} {adminCompany?.postal_code}</Text>
                  <Text>{adminCompany?.primary_email}</Text>
                  <Text>{adminCompany?.primary_phone}</Text>
                </div>
                <div style={{ flex: 1 }}>
                  <Text>NPI: {adminCompany?.npi}</Text>
                  <Text>TAX ID: {adminCompany?.tax_id}</Text>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%", paddingTop: 8 }}>
                <div style={{ fontWeight: "bold" }}>
                  Requirements
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div style={{ width: "100%" }}>
              <div onClick={() => {
                setShowPayers(!showPayers)
              }}>
                <Text fontWeight="bold" fontSize="x-large">Payers</Text>
              </div>
              <div>
                {showPayers && (
                  <Table>
                    <Thead>
                      <Tr my='.8rem' pl='0px' color='gray.400'>
                        <Th color='gray.400' >
                          Name
                        </Th>
                        <Th color='gray.400' >
                          External ID
                        </Th>
                        <Th color='gray.400' >
                          Status
                        </Th>
                        <Th color='gray.400' >
                          Action
                        </Th>
                      </Tr>
                    </Thead>

                    {_.orderBy(payers, "name").map((p) => {
                      return <AdminPayerRow data={p} />
                    })}

                  </Table>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </Flex >
    </>
  );

}

export default AdminCompany;
