// Chakra imports
import { Button, Flex } from "@aws-amplify/ui-react";
import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import React, { useEffect } from "react";
import { Bucket } from "services/storage";
import TablesPayerRow from "./TablesPayerRow";
import Store from "services/Store";
import TablesPayerRowAdd from "./TablesPayerRowAdd";
import ValidInput from "ui-components/ValidInput";
import Fn from "services/Fn";

const ListPayersAdd = ({ title, captions, data }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [search, setSearch] = React.useState();

  const [customPayerName, setCustomPayerName] = React.useState();
  const [customPayerExternalId, setCustomPayerExternalId] = React.useState();
  const [customPayer, setCustomPayer] = React.useState();

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p="6px 0px 22px 0px">
        <Flex>
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            {title}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <ValidInput
            label="Search"
            placeholder="Search"
            value={Store.search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {captions.map((caption, idx) => {
                  return (
                    <Th
                      color="gray.400"
                      key={idx}
                      ps={idx === 0 ? "0px" : null}
                    >
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data
                .filter(
                  (x) =>
                    search == null ||
                    x.name.toLowerCase().includes(search.toLowerCase()) ||
                    x.external_id.toLowerCase().includes(search.toLowerCase())
                )
                .map((row) => {
                  return (
                    <TablesPayerRowAdd
                      key={row.id}
                      data={row}
                      onClose={() => {
                        // remove id from list
                        window.location.reload();
                      }}
                    />
                  );
                })}
              <Tr>
                <Td>
                  <ValidInput
                    placeholder="Name"
                    value={customPayerName}
                    onChange={(e) => {
                      setCustomPayerName(e.target.value);
                    }}
                  />
                </Td>
                <Td>
                  <ValidInput
                    placeholder="External ID"
                    value={customPayerExternalId}
                    onChange={(e) => {
                      setCustomPayerExternalId(e.target.value);
                    }}
                  />
                </Td>
                <Td>
                  <Button
                    disabled={
                      customPayerName == null || customPayerExternalId == null
                    }
                    size="sm"
                    onClick={async () => {
                      Fn.InternalController("AddCustomPayerSetting", {
                        name: customPayerName,
                        external_id: customPayerExternalId,
                      }).then((res) => {
                        window.location.reload();
                      });
                    }}
                  >
                    Add
                  </Button>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default ListPayersAdd;
