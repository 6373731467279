import { Logger } from "aws-amplify";

const logger = new Logger('Logger', 'INFO');

export const auth_listener = (data) => {

    switch (data.payload.event) {
        case 'signIn':
            logger.info('user signed in');
            window.location.href = "#/dashboard";
            break;
        case 'signUp':
            logger.info('user signed up');
            window.location.href = "#/dashboard";
            break;
        case 'signOut':
            logger.info('user signed out');
            window.location.href = "#/dashboard";
            break;
        case 'signIn_failure':
            logger.info('user sign in failed');
            break;
        case 'configured':
            logger.info('the Auth module is configured');
            break;
        default:
            logger.error('Something went wrong, look at data object', data);
    }
}

