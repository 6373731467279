import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { set } from "lodash";
import React, { useEffect } from "react";
import { BsPlay, BsPlayBtn, BsPlayFill } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import Fn from "services/Fn";
import Store from "services/Store";
import { Bucket } from "services/storage";
import ValidChip from "ui-components/ValidChip";
import ValidInput from "ui-components/ValidInput";
import ValidSelect from "ui-components/ValidSelect";

function TablesConnectorRow(props) {
  const { logo, name, url, category, status, description, slug, frame, inputs, details, connectorID, settings, connectionID } = props;
  const [logoUrl, setLogoUrl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputsList, setInputsList] = React.useState([]);
  const [inputSettings, setInputSettings] = React.useState(settings);
  const [loading, setLoading] = React.useState(false);
  const onClose = () => {
    setIsOpen(false)
  };
  const onOpen = () => setIsOpen(true);


  const textColor = useColorModeValue("gray.700", "white");
  let bgStatus = useColorModeValue("gray.400", "#1a202c");
  let colorStatus = useColorModeValue("white", "gray.400");


  console.log("settings", settings);

  useEffect(() => {
    if (logo) {
      Store.imageUrl(logo).then((res) => {
        setLogoUrl(res);
      })
    }
    if (inputs) {
      console.log("inputs", JSON.parse(inputs));
      let x = JSON.parse(inputs)
      setInputsList(x)
    }
  }, []);

  const incognito = localStorage.getItem("incognito") === "true" ? true : false;

  return (
    <Tr key={connectorID}>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar style={{ backgroundColor: "white" }} src={logoUrl} w="50px" h="50px" borderRadius="8px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {description}
            </Text>

            <Text fontSize="sm" color="teal.400" fontWeight="bold"
              onClick={() => {
                window.open(url, "_blank");
              }}
            >
              <a href={url} target="_blank" style={{ fontWeight: 400, fontSize: 12, fontWeight: 900, color: "teal" }}>More Details</a>
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {category}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Badge
          bg={status.toLowerCase() === "active" ? "green.400" : bgStatus}
          color={status.toLowerCase() === "active" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      </Td>

      <Td> {connectionID ? (
        <>
          <Button
            size="sm"
            variant="outline"
            onClick={() => {
              onOpen();
            }}
          >
            <EditIcon />
          </Button>
          {status.toLowerCase() == "active" && (
            <Button ml={4}
              disabled={loading}
              isLoading={loading}
              size="sm"
              variant="outline"
              colorScheme="red"
              onClick={() => {
                setLoading(true)
                Fn.InternalController("RunConnection", {
                  id: connectionID,
                }).then((res) => {
                  console.log("RunConnection", JSON.parse(res));
                  // alert("Success: Connection has been run")
                  setLoading(false)
                }).catch((err) => {
                  console.log("RunConnection", err);
                  // alert("Error: Connection failed to run")
                  setLoading(false)
                });
              }}
            >
              <FaPlay />
            </Button>)}
        </>
      ) : (
        <Button
          size="sm"
          variant="outline"
          onClick={() => {
            onOpen();
          }}
        >
          <ViewIcon />
        </Button>
      )}

      </Td>
      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Setup: {name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ display: "flex" }}>
              <div style={{ minWidth: 400, width: "100%", padding: 20 }}>
                <div style={{ border: "2px solid #ccc", borderRadius: 10, overflow: "hidden", }}>
                  <iframe
                    src={frame}
                    style={{ width: "100%", height: "calc(100vh - 200px)" }}
                  ></iframe></div>
              </div>
              <div style={{ minWidth: 400, width: "100%", padding: 20 }}>
                <div style={{ display: "flex" }}>

                  <div style={{}}>
                    <img src={logoUrl} style={{ width: 160, borderRadius: 10, border: "2px #ccc solid", }} />
                  </div>
                  <div style={{ paddingLeft: 16 }}>
                    <div style={{ fontSize: 44, fontWeight: 600 }}>{name}</div>
                    <div style={{ display: "flex", marginTop: -8, marginLeft: -4 }}>
                      <ValidChip label={status} color="teal" />
                      <ValidChip label={category} />
                    </div>
                    <div style={{ fontWeight: 400 }}>{description}</div>
                    <a href={url} target="_blank" style={{ fontWeight: 400, fontSize: 12, fontWeight: 900, color: "teal" }}>More Details</a>
                  </div>
                </div>
                <div>
                  {details}
                </div>
                <div style={{ fontSize: 24, fontWeight: "bold", marginTop: 20, marginBottom: 10 }}>
                  Settings
                </div>


                <div>{inputs && inputsList.map((i) => {
                  return (
                    <>
                      {incognito ?
                        (
                          <>
                            <ValidInput
                              label={i.label}
                              description={i.description}
                              value={"Example Setting"} />

                          </>
                        )
                        : (<>

                          {
                            i.key == "con.schedule" ? (
                              <ValidSelect
                                label={i.label}
                                description={i.description}
                                value={inputSettings && inputSettings[i.key]}
                                options={[
                                  { label: "None", value: "0" },
                                  { label: "Every 15 Minutes", value: "1" },
                                  { label: "Every 30 Minutes", value: "2" },
                                  { label: "Hourly", value: "3" },
                                  { label: "Daily", value: "4" },
                                ]}
                                onChange={(e) => {
                                  setInputSettings({ ...inputSettings, [i.key]: e.target.value })
                                }} />
                            ) : (
                              <ValidInput label={i.label} description={i.description} value={inputSettings && inputSettings[i.key]
                              } onChange={(e) => {
                                setInputSettings({ ...inputSettings, [i.key]: e.target.value })
                              }} />)}
                        </>)
                      }
                    </>
                  )
                })}

                </div >
              </div>
            </div>
          </ModalBody>
          <ModalFooter>

            <Spinner size="sm" color="teal" style={{ display: loading ? "inline-block" : "none" }} />
            <Button variant="ghost" colorScheme="grey" mr={3} onClick={onClose}>
              Close
            </Button>
            {connectionID ? (<Button
              disabled={loading || incognito}
              colorScheme="teal"
              onClick={() => {
                let schedule = inputSettings["con.schedule"] ? inputSettings["con.schedule"] : "0"
                setLoading(true)
                Fn.InternalController("UpdateConnection", {
                  id: connectionID,
                  name: name,
                  connectorID: connectorID,
                  logo: logo,
                  settings: inputSettings,
                  schedule: parseInt(schedule),
                }).then((res) => {
                  console.log("AddConnection", res);
                  setLoading(false)
                });
              }}
            > Update Settings</Button>) : (
              <Button
                disabled={loading}
                colorScheme="teal"
                onClick={() => {
                  setLoading(true)
                  // if a setting with "con.schedule" exists, then we need to add a schedule
                  let schedule = inputSettings["con.schedule"] ? inputSettings["con.schedule"] : "0"
                  Fn.InternalController("AddConnection", {
                    name: name,
                    connectorID: connectorID,
                    logo: logo,
                    settings: settings,
                    schedule: parseInt(schedule),
                  }).then((res) => {
                    console.log("AddConnection", res);
                    onClose();
                    setLoading(false)
                  }).catch((err) => {
                    console.log("AddConnection", err);
                    setLoading(false)
                  });
                }}
              >{loading ? "Loading..." : "Add Connection"}</Button>)}
            {connectionID && <Button
              style={{ marginLeft: 10 }}
              disabled={loading}
              colorScheme="red"
              onClick={() => {
                setLoading(true)
                Fn.InternalController("RunConnection", {
                  id: connectionID,
                }).then((res) => {
                  console.log("RunConnection", JSON.parse(res));
                  // alert("Success: Connection has been run")
                  setLoading(false)
                }).catch((err) => {
                  console.log("RunConnection", err);
                  // alert("Error: Connection failed to run")
                  setLoading(false)
                });
              }}
            >Run Connector</Button>}

          </ModalFooter>
        </ModalContent>
      </Modal>
    </Tr >
  );
}

export default TablesConnectorRow;
