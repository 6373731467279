/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addMockingData = /* GraphQL */ `
  query AddMockingData {
    addMockingData
  }
`;
export const InternalController = /* GraphQL */ `
  query InternalController {
    InternalController
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      image
      name
      email
      auth_id
      active
      owner
      tenant
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        name
        email
        auth_id
        active
        owner
        tenant
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayers = /* GraphQL */ `
  query GetPayers($id: ID!) {
    getPayers(id: $id) {
      id
      name
      type
      use_dob
      use_ins_number
      external_id
      verify_id
      url
      notes
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayers = /* GraphQL */ `
  query ListPayers(
    $filter: ModelPayersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        use_dob
        use_ins_number
        external_id
        verify_id
        url
        notes
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayerSetting = /* GraphQL */ `
  query GetPayerSetting($id: ID!) {
    getPayerSetting(id: $id) {
      id
      name
      type
      payer_org_id
      external_id
      state
      url
      notes
      status
      route
      companyID
      payerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayerSettings = /* GraphQL */ `
  query ListPayerSettings(
    $filter: ModelPayerSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayerSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        payer_org_id
        external_id
        state
        url
        notes
        status
        route
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConnections = /* GraphQL */ `
  query GetConnections($id: ID!) {
    getConnections(id: $id) {
      id
      name
      logo
      status
      meta
      schedule
      settings
      companyID
      connectorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConnections = /* GraphQL */ `
  query ListConnections(
    $filter: ModelConnectionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        status
        meta
        schedule
        settings
        companyID
        connectorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanies = /* GraphQL */ `
  query GetCompanies($id: ID!) {
    getCompanies(id: $id) {
      id
      name
      address
      city
      state
      postal_code
      primary_phone
      primary_email
      logo
      npi
      tax_id
      consumers {
        nextToken
        __typename
      }
      crmContacts {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      connectors {
        nextToken
        __typename
      }
      requirements {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompaniesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequirements = /* GraphQL */ `
  query GetRequirements($id: ID!) {
    getRequirements(id: $id) {
      id
      name
      description
      status
      link
      link_text
      icon
      meta
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRequirements = /* GraphQL */ `
  query ListRequirements(
    $filter: ModelRequirementsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequirements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        link
        link_text
        icon
        meta
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConsumers = /* GraphQL */ `
  query GetConsumers($id: ID!) {
    getConsumers(id: $id) {
      id
      meta
      companyID
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConsumers = /* GraphQL */ `
  query ListConsumers(
    $filter: ModelConsumersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsumers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        meta
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCredits = /* GraphQL */ `
  query GetCredits($id: ID!) {
    getCredits(id: $id) {
      id
      name
      type
      amount
      status
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCredits = /* GraphQL */ `
  query ListCredits(
    $filter: ModelCreditsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCredits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        amount
        status
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivityLog = /* GraphQL */ `
  query GetActivityLog($id: ID!) {
    getActivityLog(id: $id) {
      id
      title
      note
      data
      status
      activity
      companyID
      connectorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActivityLogs = /* GraphQL */ `
  query ListActivityLogs(
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        note
        data
        status
        activity
        companyID
        connectorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivities = /* GraphQL */ `
  query GetActivities($id: ID!) {
    getActivities(id: $id) {
      id
      title
      note
      status
      planned_date
      planned_day
      external_id
      external_consumer_id
      lastname
      firstname
      ins_id
      dob
      ins_number
      payer_org_id
      origin_system_payer_name
      origin_system_payer_id
      origin_system_payer_code
      origin_system_payer_description
      origin_system_billing_ord
      details
      connectorID
      connectionID
      companyID
      payerID
      activityLogs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        note
        status
        planned_date
        planned_day
        external_id
        external_consumer_id
        lastname
        firstname
        ins_id
        dob
        ins_number
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCrmContact = /* GraphQL */ `
  query GetCrmContact($id: ID!) {
    getCrmContact(id: $id) {
      id
      firstName
      lastName
      email
      phone
      isCompany
      meta
      notes
      companyID
      company {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      interactions {
        nextToken
        __typename
      }
      lists {
        nextToken
        __typename
      }
      relatedContacts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCrmContacts = /* GraphQL */ `
  query ListCrmContacts(
    $filter: ModelCrmContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrmContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCrmContactConnection = /* GraphQL */ `
  query GetCrmContactConnection($id: ID!) {
    getCrmContactConnection(id: $id) {
      id
      contactID
      relatedContactID
      contact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      relatedContact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCrmContactConnections = /* GraphQL */ `
  query ListCrmContactConnections(
    $filter: ModelCrmContactConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrmContactConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contactID
        relatedContactID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCrmAddress = /* GraphQL */ `
  query GetCrmAddress($id: ID!) {
    getCrmAddress(id: $id) {
      id
      street
      city
      state
      postalCode
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCrmAddresses = /* GraphQL */ `
  query ListCrmAddresses(
    $filter: ModelCrmAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrmAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        street
        city
        state
        postalCode
        country
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCrmInteraction = /* GraphQL */ `
  query GetCrmInteraction($id: ID!) {
    getCrmInteraction(id: $id) {
      id
      crmContactID
      type
      date
      notes
      details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCrmInteractions = /* GraphQL */ `
  query ListCrmInteractions(
    $filter: ModelCrmInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrmInteractions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        crmContactID
        type
        date
        notes
        details
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCrmList = /* GraphQL */ `
  query GetCrmList($id: ID!) {
    getCrmList(id: $id) {
      id
      contacts {
        nextToken
        __typename
      }
      type
      date
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCrmLists = /* GraphQL */ `
  query ListCrmLists(
    $filter: ModelCrmListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrmLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        date
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConsumersCompanies = /* GraphQL */ `
  query GetConsumersCompanies($id: ID!) {
    getConsumersCompanies(id: $id) {
      id
      companiesId
      consumersId
      companies {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      consumers {
        id
        meta
        companyID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyID
      __typename
    }
  }
`;
export const listConsumersCompanies = /* GraphQL */ `
  query ListConsumersCompanies(
    $filter: ModelConsumersCompaniesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsumersCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companiesId
        consumersId
        createdAt
        updatedAt
        companyID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactLists = /* GraphQL */ `
  query GetContactLists($id: ID!) {
    getContactLists(id: $id) {
      id
      crmContactId
      crmListId
      crmContact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      crmList {
        id
        type
        date
        notes
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactLists = /* GraphQL */ `
  query ListContactLists(
    $filter: ModelContactListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        crmContactId
        crmListId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByCompanyID = /* GraphQL */ `
  query UsersByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        image
        name
        email
        auth_id
        active
        owner
        tenant
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const payerSettingsByCompanyID = /* GraphQL */ `
  query PayerSettingsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPayerSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payerSettingsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        payer_org_id
        external_id
        state
        url
        notes
        status
        route
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const payerSettingsByPayerID = /* GraphQL */ `
  query PayerSettingsByPayerID(
    $payerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPayerSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payerSettingsByPayerID(
      payerID: $payerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        payer_org_id
        external_id
        state
        url
        notes
        status
        route
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const connectionsBySchedule = /* GraphQL */ `
  query ConnectionsBySchedule(
    $schedule: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionsBySchedule(
      schedule: $schedule
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        logo
        status
        meta
        schedule
        settings
        companyID
        connectorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const connectionsByCompanyID = /* GraphQL */ `
  query ConnectionsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        logo
        status
        meta
        schedule
        settings
        companyID
        connectorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const connectionsByConnectorID = /* GraphQL */ `
  query ConnectionsByConnectorID(
    $connectorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConnectionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionsByConnectorID(
      connectorID: $connectorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        logo
        status
        meta
        schedule
        settings
        companyID
        connectorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const requirementsByCompanyID = /* GraphQL */ `
  query RequirementsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRequirementsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requirementsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        link
        link_text
        icon
        meta
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const consumersByCompanyID = /* GraphQL */ `
  query ConsumersByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConsumersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consumersByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meta
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const creditsByCompanyID = /* GraphQL */ `
  query CreditsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCreditsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        amount
        status
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activityLogsByActivity = /* GraphQL */ `
  query ActivityLogsByActivity(
    $activity: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityLogsByActivity(
      activity: $activity
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        data
        status
        activity
        companyID
        connectorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activityLogsByCompanyID = /* GraphQL */ `
  query ActivityLogsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityLogsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        data
        status
        activity
        companyID
        connectorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activityLogsByConnectorID = /* GraphQL */ `
  query ActivityLogsByConnectorID(
    $connectorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activityLogsByConnectorID(
      connectorID: $connectorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        data
        status
        activity
        companyID
        connectorID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activitiesByPlanned_day = /* GraphQL */ `
  query ActivitiesByPlanned_day(
    $planned_day: String!
    $sortDirection: ModelSortDirection
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByPlanned_day(
      planned_day: $planned_day
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        status
        planned_date
        planned_day
        external_id
        external_consumer_id
        lastname
        firstname
        ins_id
        dob
        ins_number
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activitiesByExternal_id = /* GraphQL */ `
  query ActivitiesByExternal_id(
    $external_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByExternal_id(
      external_id: $external_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        status
        planned_date
        planned_day
        external_id
        external_consumer_id
        lastname
        firstname
        ins_id
        dob
        ins_number
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activitiesByExternal_consumer_id = /* GraphQL */ `
  query ActivitiesByExternal_consumer_id(
    $external_consumer_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByExternal_consumer_id(
      external_consumer_id: $external_consumer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        status
        planned_date
        planned_day
        external_id
        external_consumer_id
        lastname
        firstname
        ins_id
        dob
        ins_number
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activitiesByIns_numberAndStatus = /* GraphQL */ `
  query ActivitiesByIns_numberAndStatus(
    $ins_number: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByIns_numberAndStatus(
      ins_number: $ins_number
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        status
        planned_date
        planned_day
        external_id
        external_consumer_id
        lastname
        firstname
        ins_id
        dob
        ins_number
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activitiesByConnectorID = /* GraphQL */ `
  query ActivitiesByConnectorID(
    $connectorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByConnectorID(
      connectorID: $connectorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        status
        planned_date
        planned_day
        external_id
        external_consumer_id
        lastname
        firstname
        ins_id
        dob
        ins_number
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activitiesByConnectionID = /* GraphQL */ `
  query ActivitiesByConnectionID(
    $connectionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByConnectionID(
      connectionID: $connectionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        status
        planned_date
        planned_day
        external_id
        external_consumer_id
        lastname
        firstname
        ins_id
        dob
        ins_number
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activitiesByCompanyID = /* GraphQL */ `
  query ActivitiesByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        status
        planned_date
        planned_day
        external_id
        external_consumer_id
        lastname
        firstname
        ins_id
        dob
        ins_number
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activitiesByPayerID = /* GraphQL */ `
  query ActivitiesByPayerID(
    $payerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByPayerID(
      payerID: $payerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        note
        status
        planned_date
        planned_day
        external_id
        external_consumer_id
        lastname
        firstname
        ins_id
        dob
        ins_number
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const crmContactsByCompanyID = /* GraphQL */ `
  query CrmContactsByCompanyID(
    $companyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrmContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crmContactsByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const crmContactConnectionsByContactID = /* GraphQL */ `
  query CrmContactConnectionsByContactID(
    $contactID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrmContactConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crmContactConnectionsByContactID(
      contactID: $contactID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contactID
        relatedContactID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const crmInteractionsByCrmContactID = /* GraphQL */ `
  query CrmInteractionsByCrmContactID(
    $crmContactID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrmInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crmInteractionsByCrmContactID(
      crmContactID: $crmContactID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        crmContactID
        type
        date
        notes
        details
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const consumersCompaniesByCompaniesId = /* GraphQL */ `
  query ConsumersCompaniesByCompaniesId(
    $companiesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConsumersCompaniesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consumersCompaniesByCompaniesId(
      companiesId: $companiesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companiesId
        consumersId
        createdAt
        updatedAt
        companyID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const consumersCompaniesByConsumersId = /* GraphQL */ `
  query ConsumersCompaniesByConsumersId(
    $consumersId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConsumersCompaniesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consumersCompaniesByConsumersId(
      consumersId: $consumersId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companiesId
        consumersId
        createdAt
        updatedAt
        companyID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactListsByCrmContactId = /* GraphQL */ `
  query ContactListsByCrmContactId(
    $crmContactId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactListsByCrmContactId(
      crmContactId: $crmContactId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        crmContactId
        crmListId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactListsByCrmListId = /* GraphQL */ `
  query ContactListsByCrmListId(
    $crmListId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactListsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactListsByCrmListId(
      crmListId: $crmListId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        crmContactId
        crmListId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConnectors = /* GraphQL */ `
  query GetConnectors($id: ID!) {
    getConnectors(id: $id) {
      id
      logo
      name
      url
      category
      status
      description
      details
      slug
      frame
      inputs
      meta
      activityLogs {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConnectors = /* GraphQL */ `
  query ListConnectors(
    $filter: ModelConnectorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        logo
        name
        url
        category
        status
        description
        details
        slug
        frame
        inputs
        meta
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompaniesConnectors = /* GraphQL */ `
  query GetCompaniesConnectors($id: ID!) {
    getCompaniesConnectors(id: $id) {
      id
      connectorsId
      companiesId
      connectors {
        id
        logo
        name
        url
        category
        status
        description
        details
        slug
        frame
        inputs
        meta
        createdAt
        updatedAt
        __typename
      }
      companies {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompaniesConnectors = /* GraphQL */ `
  query ListCompaniesConnectors(
    $filter: ModelCompaniesConnectorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompaniesConnectors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        connectorsId
        companiesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesConnectorsByConnectorsId = /* GraphQL */ `
  query CompaniesConnectorsByConnectorsId(
    $connectorsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompaniesConnectorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesConnectorsByConnectorsId(
      connectorsId: $connectorsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        connectorsId
        companiesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesConnectorsByCompaniesId = /* GraphQL */ `
  query CompaniesConnectorsByCompaniesId(
    $companiesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompaniesConnectorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesConnectorsByCompaniesId(
      companiesId: $companiesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        connectorsId
        companiesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
