import {
  Avatar,
  Badge,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { set } from "lodash";
import React, { useEffect } from "react";
import Fn from "services/Fn";
import Store from "services/Store";
import { Bucket } from "services/storage";
import ValidChip from "ui-components/ValidChip";
import ValidInput from "ui-components/ValidInput";

function TablesPayerRow(props) {
  const { data } = props;
  const [logoUrl, setLogoUrl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputsList, setInputsList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [inputPayerExternalID, setInputPayerExternalID] = React.useState(data.external_id);
  const [external_id, setExternal_id] = React.useState(data.external_id);

  const onClose = () => {
    setIsOpen(false)
  };
  const onOpen = () => setIsOpen(true);


  const textColor = useColorModeValue("gray.700", "white");
  let bgStatus = useColorModeValue("gray.400", "#1a202c");
  let colorStatus = useColorModeValue("white", "gray.400");




  return (
    <Tr key={data.id} onClick={() => {
      window.location.href = `#/crm/admin-company/${data.id}`
    }}>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {logoUrl && (
            <Avatar style={{ backgroundColor: "white" }} src={logoUrl} w="50px" h="50px" borderRadius="8px" me="18px" />
          )}
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {data.name}
            </Text>

          </Flex>
        </Flex>
      </Td>


      <Td>
        <Button p="0px" bg="transparent" variant="no-hover"

        >
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"

          >
            View
          </Text>
        </Button>
      </Td>
      <Modal isOpen={isOpen} onClose={onClose} size="full" >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>{data.name}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <div style={{ display: "flex" }}>
              <div>
                <div style={{ fontSize: 24, fontWeight: 700 }}>Connections</div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>

            <Spinner size="sm" color="teal" style={{ display: loading ? "inline-block" : "none" }} />


            <Button variant="ghost" colorScheme="grey" mr={3} onClick={onClose}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </Tr >
  );
}

export default TablesPayerRow;
