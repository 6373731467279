import { atom } from "recoil";

// company state
export const companyState = atom({
    key: 'companyState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// team state
export const teamState = atom({
    key: 'teamState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// profileState
export const profileState = atom({
    key: 'profileState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// profileImageState
export const profileImageState = atom({
    key: 'profileImageState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// activityState
export const activityState = atom({
    key: 'activityState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// activityLogState
export const activityLogState = atom({
    key: 'activityLogState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// activitiesState
export const activitiesState = atom({
    key: 'activitiesState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});