// Chakra imports
import { Button, Flex } from "@aws-amplify/ui-react";
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import React, { useEffect } from "react";
import { Bucket } from "services/storage";
import TablesConnectorRow from "./TablesConnectorRow";
import Store from "services/Store";

const MyConnections = ({ title, captions, data, connectors }) => {
  const textColor = useColorModeValue("gray.700", "white");


  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Flex>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            {title}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              let connector = _.find(connectors, { id: row.connectorID })
              if (!connector) return null
              return (
                <TablesConnectorRow
                  key={row.id}
                  name={connector.name}
                  logo={connector.logo}
                  url={connector.url}
                  category={connector.category}
                  description={connector.description}
                  slug={connector.slug}
                  status={row.status}
                  frame={connector.frame}
                  inputs={connector.inputs}
                  details={connector.details}
                  connectorID={connector.id}
                  settings={row.settings && JSON.parse(row.settings)}
                  connectionID={row.id}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default MyConnections;
