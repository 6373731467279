import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { createCrmContact } from 'graphql/mutations';
import { updateCrmContact } from 'graphql/mutations';
import { CrmContactCreateForm } from 'ui-components';
import { CrmContactUpdateForm } from 'ui-components';
import { getCrmContact } from 'graphql/queries';
import { set } from 'lodash';
import { getCompanies } from 'graphql/queries';


const ContactPopup = ({ isOpen, onClose, contact, onRefresh }) => {
    const [formState, setFormState] = useState({});
    const [extContact, setExtContact] = useState();
    const [company, setCompany] = useState();

    useEffect(() => {

        if (contact) {
            // pull the full contact with relationships from api
            API.graphql(graphqlOperation(getCrmContact, { id: contact.id })).then(result => {
                setExtContact(result.data.getCrmContact);
                console.log("ext Contact", result.data.getCrmContact)
                // now get the company info
                if (result.data.getCrmContact.companyID) {
                    API.graphql(graphqlOperation(getCompanies, { id: result.data.getCrmContact.companyID })).then(result2 => {
                        setCompany(result2.data.getCompany);
                        console.log("company", result2.data.getCompany)
                    })
                }
            })

        }

    }
        , []);

    const handleSave = async () => {
        try {
            const operationInput = { input: _.omitBy(_.omitBy({ ...formState }, _.isNull), _.isEmpty) };

            if (contact) operationInput.input = { ...operationInput.input, id: contact.id };
            // Additional logic here for handling relationships


            if (contact) {
                await API.graphql(graphqlOperation(updateCrmContact, operationInput));
            } else {
                await API.graphql(graphqlOperation(createCrmContact, operationInput));
            }

        } catch (err) {
            console.error('Error saving contact:', err);
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={contact ? 'full' : 'md'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{contact ? 'Edit Contact' : 'Create Contact'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {contact ?
                        (
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: 1 }}>
                                    <CrmContactUpdateForm
                                        onBlur={handleSave}
                                        crmContact={contact}
                                        onChange={(fields) => {

                                            Object.keys(fields).forEach(key => {
                                                if (fields[key]) {
                                                    if (typeof fields[key] === 'string') {
                                                        formState[key] = fields[key].trim()
                                                    } else {
                                                        formState[key] = fields[key]
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    {extContact && extContact.company && extContact.company.id}

                                </div>
                            </div>
                        )
                        : (
                            null
                            // <CrmContactCreateForm
                            //     onChange={(fields) => {

                            //         Object.keys(fields).forEach(key => {
                            //             if (fields[key]) {
                            //                 if (typeof fields[key] === 'string') {
                            //                     formState[key] = fields[key].trim()
                            //                 } else {
                            //                     formState[key] = fields[key]
                            //                 }
                            //             }
                            //         })
                            //     }}
                            // />
                        )}
                </ModalBody>

                <ModalFooter>
                    {contact && (
                        <Button variant="ghost" onClick={onClose}>Close</Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ContactPopup;
