import {
  Avatar,
  Badge,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Fn from "services/Fn";
import Store from "services/Store";
import { Bucket } from "services/storage";
import ValidChip from "ui-components/ValidChip";
import ValidInput from "ui-components/ValidInput";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import ValidEditor from "ui-components/ValidEditor";
import ValidSelect from "ui-components/ValidSelect";

function TablesPayerRow(props) {
  const { data } = props;
  const [logoUrl, setLogoUrl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputsList, setInputsList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [inputPayerExternalID, setInputPayerExternalID] = React.useState(
    data.external_id
  );
  const [status, setStatus] = React.useState(data.status);
  const [external_id, setExternal_id] = React.useState(data.external_id);
  const [url, setUrl] = React.useState(data.url);
  const [notes, setNotes] = React.useState();

  const onClose = () => {
    setIsOpen(false);
  };
  const onOpen = () => setIsOpen(true);

  const textColor = useColorModeValue("gray.700", "white");
  let bgStatus = useColorModeValue("gray.400", "#1a202c");
  let colorStatus = useColorModeValue("white", "gray.400");

  useEffect(() => {
    console.log("data", data);
    if (data.notes) {
      let contentState;
      if (typeof data.notes === "string") {
        // Assuming the notes are saved as a stringified RawDraftContentState
        const parsedData = JSON.parse(data.notes);
        contentState = convertFromRaw(parsedData);
      } else if (data.notes.blocks && data.notes.entityMap) {
        // If notes are already in RawDraftContentState format
        contentState = convertFromRaw(data.notes);
      } else {
        console.error("Notes are not in a recognized format");
        return;
      }
      setNotes(EditorState.createWithContent(contentState));
    }
  }, [data]);

  return (
    <Tr
      key={data.id}
      onClick={() => {
        onOpen();
      }}
    >
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {logoUrl && (
            <Avatar
              style={{ backgroundColor: "white" }}
              src={logoUrl}
              w="50px"
              h="50px"
              borderRadius="8px"
              me="18px"
            />
          )}
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {data.name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal"></Text>

            <Text
              fontSize="sm"
              color="teal.400"
              fontWeight="bold"
              onClick={() => {}}
            ></Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {external_id}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Badge
          bg={data.status.toLowerCase() === "active" ? "green.400" : bgStatus}
          color={data.status.toLowerCase() === "active" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {data.status}
        </Badge>
        {data.type && (
          <Badge
            bg={data.type.toLowerCase() === "active" ? "green.400" : bgStatus}
            color={data.type.toLowerCase() === "active" ? "white" : colorStatus}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
            ml={2}
          >
            {data.type}
          </Badge>
        )}
      </Td>

      <Td>
        <Button p="0px" bg="transparent" variant="no-hover">
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            View
          </Text>
        </Button>
      </Td>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Setup: {data.name}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, paddingRight: 10 }}>
                <div>Notes </div>
                <div
                  style={{
                    border: "1px solid #e1e1e1",
                    margin: 14,
                    height: "calc(100vh - 260px)",
                    borderRadius: 8,
                    padding: 8,
                    boxShadow: "0px 0px 21px 0px rgba(0,0,0,0.1)",
                    backgroundColor: "#fff",
                    overflow: "scroll",
                  }}
                >
                  <ValidEditor
                    label={"Notes"}
                    value={notes}
                    onChange={(e) => {
                      setNotes(e);
                    }}
                  />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ fontSize: 24, fontWeight: 700 }}>Details</div>
                <Badge
                  bg={data.status === "active" ? "green.400" : bgStatus}
                  color={data.status === "active" ? "white" : colorStatus}
                  fontSize="16px"
                  p="3px 10px"
                  borderRadius="8px"
                >
                  {data.status}
                </Badge>
                {data.type && (
                  <Badge
                    bg={data.type === "active" ? "green.400" : bgStatus}
                    color={data.type === "active" ? "white" : colorStatus}
                    fontSize="16px"
                    p="3px 10px"
                    borderRadius="8px"
                    ml={2}
                  >
                    {data.type}
                  </Badge>
                )}
                <div>
                  <b>State:</b> {data.state}
                </div>
                <div>
                  <b>Payer ID:</b> {data.external_id}
                </div>
                <div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>
                  Setup
                </div>
                <ValidInput
                  label={"External ID"}
                  value={inputPayerExternalID}
                  description={`The ID in your system that is used for this payer.  So that we can map it our internal systems ID to verify properly.`}
                  onChange={(e) => {
                    setInputPayerExternalID(e.target.value);
                  }}
                />

                <ValidInput
                  label={"Login Url"}
                  value={url}
                  description={`Quick link to payer login page.`}
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ flex: 1 }}>
                <Button
                  variant="ghost"
                  colorScheme="orange"
                  mr={3}
                  onClick={() => {
                    setLoading(true);
                    Fn.InternalController("RemovePayerSetting", {
                      id: data.id,
                    }).then((res) => {
                      console.log("RemovePayerSetting", res);
                      setLoading(false);
                      onClose();
                      props.onEdit();
                    });
                  }}
                >
                  Delete
                </Button>
              </div>
              <div>
                <Spinner
                  size="sm"
                  color="teal"
                  style={{ display: loading ? "inline-block" : "none" }}
                />

                <Button
                  variant="ghost"
                  colorScheme="grey"
                  mr={3}
                  onClick={onClose}
                >
                  Close
                </Button>

                <Button
                  colorScheme="teal"
                  mr={3}
                  onClick={() => {
                    Fn.InternalController("UpdatePayerSetting", {
                      id: data.id,
                      external_id: inputPayerExternalID,
                      notes: JSON.stringify(
                        convertToRaw(notes.getCurrentContent())
                      ),
                      url: url,
                    }).then((res) => {
                      console.log("UpdatePayerSetting", res);
                      setExternal_id(inputPayerExternalID);
                      onClose();
                    });
                  }}
                >
                  Update
                </Button>
              </div>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Tr>
  );
}

export default TablesPayerRow;
