import React, { useEffect } from "react";
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";



function Docs() {
  const [clients, setClients] = React.useState([]);

  return (
    <Flex direction='column' pt={{ base: "5px", md: "0px", padding: 0 }}>

      <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden", padding: 0 }}>
        <CardBody>
          {/* body of the page would go here */}
        </CardBody>
      </Card>
    </Flex >
  );

}

export default Docs;
