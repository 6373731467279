// Chakra imports
import { ExportFormat } from "@aws-sdk/client-dynamodb";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  DownloadIcon,
  NotAllowedIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormErrorIcon,
  Icon,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import IconBox from "components/Icons/IconBox";
import { ClockIcon } from "components/Icons/Icons";
import { random, set } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import {
  BsFileSpreadsheet,
  BsFillFileEarmarkSpreadsheetFill,
  BsFilter,
} from "react-icons/bs";
import {
  FaFileExport,
  FaHandHolding,
  FaHandRock,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import {
  IoAlarm,
  IoAlert,
  IoClose,
  IoCloseCircle,
  IoHandRight,
  IoInformation,
  IoLogoAngular,
  IoNotifications,
  IoWarning,
} from "react-icons/io5";
import {
  RiCloseCircleFill,
  RiCloseFill,
  RiEmphasis,
  RiNumbersLine,
} from "react-icons/ri";
import Fn from "services/Fn";
import {
  loremIpsum,
  name,
  surname,
  fullname,
  username,
} from "react-lorem-ipsum";
import API from "services/API";
import ValidSelect from "ui-components/ValidSelect";

const DayStatistics = ({
  title,
  day,
  showAll,
  refreshActivities,
  showWorkbench,
  selected,
  ...rest
}) => {
  const iconTeal = useColorModeValue("teal.400", "teal.400");
  const iconRed = useColorModeValue("red.400", "red.300");
  const iconGrey = useColorModeValue("gray.300", "gray.300");
  const iconPrimary = useColorModeValue("blue.200", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const iconWarning = useColorModeValue("orange.300", "orange.400");
  const [loading, setLoading] = React.useState(false);
  const [showVerify, setShowVerify] = React.useState(true);
  const [activitiesRemaining, setActivitiesRemaining] = React.useState(0);
  const [activityList, setActivityList] = React.useState();
  const [selectedId, setSelectedId] = React.useState();
  const [hoverId, setHoverId] = React.useState();
  const [hasPending, setHasPending] = React.useState(false);
  const [allActivities, setAllActivities] = React.useState();

  let statusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Decline ", value: "declined" },
    { label: "Approved*", value: "approved" },
    { label: "Closed*", value: "closed" },
    { label: "Error", value: "error" },
  ];

  if (process.env.REACT_APP_ENV_NAME == "staging") {
    statusOptions = [
      { label: "Pending", value: "test-pending" },
      { label: "Decline ", value: "test-declined" },
      { label: "Approved*", value: "test-approved" },
      { label: "Closed*", value: "test-closed" },
      { label: "Error", value: "test-error" },
      { label: "Verified", value: "test-verified" },
    ];
  }

  useEffect(() => {
    console.log("activities");
    getActivities(rest.filter);
  }, []);

  async function getActivities(filter = null) {
    await API.activitiesByDay(day, filter).then((res) => {
      if (res.length != 0) {
        console.log("activitiesByDay" + day, res);
        let activities = _.values(_.groupBy(res, "external_consumer_id"));
        console.log("activitiesByConsumer" + day, activities);

        setActivityList(activities);

        setHasPending(res?.filter((a) => a.status == "pending").length > 0);

        setAllActivities(res);

        setActivitiesRemaining(
          res?.filter(
            (a) =>
              a.status != "verified" &&
              a.status != "approved" &&
              a.status != "closed"
          ).length
        );
      }
    });
  }

  function Activities({ activities }) {
    let a = activities[0];

    let done = true;
    let errors = 0;
    let status = activities[0].status.toLowerCase();
    for (let x of activities) {
      if (
        x.status != "verified" &&
        x.status != "approved" &&
        x.status != "closed"
      ) {
        errors++;
        done = false;
        status = x.status.toLowerCase();
      }
    }

    if ((!showAll && !done) || showAll) {
      return (
        <div
          style={{
            marginBottom: 4,
            backgroundColor: "#eeeeee90",
            padding: 4,
            borderRadius: 16,
            marginBottom: 8,
          }}
        >
          <div
            style={{
              backgroundColor: "#eeeeee90",
              padding: 4,

              borderRadius: 16,
              position: "relative",
              display: "flex",
            }}
          >
            <div
              style={{
                fontWeight: 900,
                fontSize: 16,
                flex: 1,
                display: "flex",
              }}
            >
              <div
                onClick={() => {
                  // window.location.href = `#/admin/activity/` + a.id;
                }}
                style={{ marginTop: -4, flex: 1 }}
              >
                <div>
                  &nbsp;{" "}
                  <span
                    style={{
                      backgroundColor: rest.incognito ? "black" : "unset",
                      color: rest.incognito ? "black" : "unset",
                    }}
                  >
                    {a.lastname}
                  </span>
                </div>
                <div
                  style={{
                    paddingLeft: 8,
                    color: "#777",
                    marginTop: -4,
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  <span
                    style={{
                      backgroundColor: rest.incognito ? "black" : "unset",
                      color: rest.incognito ? "black" : "unset",
                    }}
                  ></span>
                </div>
                <div
                  style={{
                    paddingLeft: 8,
                    color: "#777",
                    marginTop: -4,
                    fontSize: 12,
                    fontWeight: 400,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: rest.incognito ? "black" : "unset",
                      color: rest.incognito ? "black" : "unset",
                    }}
                  >
                    {moment(a.planned_date).format("LT")}
                  </span>
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div
              style={{
                marginTop: -4,
                display: "flex",
                position: "relative",
                flexDirection: "row",
              }}
            >
              {activities
                .filter((z) => {
                  return (
                    showAll ||
                    (z.status != "verified" &&
                      z.status != "approved" &&
                      z.status != "closed")
                  );
                })
                .map((a) => {
                  return (
                    <div style={{ cursor: "pointer" }}>
                      <div
                        onClick={() => {
                          showWorkbench(true);
                          setSelectedId(a.id);
                        }}
                      >
                        <StatusIcon status={a.status} />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div>
            {activities.map((b) => {
              return (
                <>
                  {selected && selectedId == b.id && (
                    <div>
                      <div
                        style={{
                          paddingRight: 0,
                          paddingTop: 0,
                          minWidth: 160,
                          fontSize: 12,
                          fontWeight: 500,
                          paddingBottom: 4,
                          paddingTop: 4,
                          paddingLeft: 8,
                          paddingRight: 8,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          backgroundColor: "white",
                          margin: 4,
                          borderRadius: 8,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            padding: 4,
                            paddingBottom: 8,
                            cursor: "pointer",
                          }}
                        >
                          <div
                            onClick={() => {
                              window.location.href = `#/admin/activity/` + b.id;
                            }}
                            style={{
                              textAlign: "left",
                              flex: 1,
                              fontWeight: 900,
                              fontSize: 14,
                              paddingTop: 4,
                              paddingRight: 4,
                              flex: 1,
                            }}
                          >
                            {b.origin_system_payer_name}
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                            }}
                            onClick={() => {
                              window.location.href = `#/admin/activity/` + b.id;
                            }}
                          >
                            <IconBox
                              style={{ marginTop: 5, marginLeft: 4 }}
                              as="box"
                              h={"30px"}
                              w={"30px"}
                              bg={iconPrimary}
                            >
                              <ChevronRightIcon />
                            </IconBox>
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                            }}
                            onClick={() => {
                              showWorkbench(true);
                              setSelectedId(null);
                            }}
                          >
                            <IconBox
                              style={{ marginTop: 5, marginLeft: 4 }}
                              as="box"
                              h={"30px"}
                              w={"30px"}
                            >
                              <CloseIcon />
                            </IconBox>
                          </div>
                        </div>
                        <div style={{ width: "100%", paddingBottom: 8 }}>
                          <ValidSelect
                            label=""
                            description={""}
                            placeholder={
                              b.status ? _.capitalize(b.status) : "Set Status"
                            }
                            options={statusOptions}
                            onChange={(e) => {
                              setLoading(true);
                              // setSelectStatus(e.target.value);
                              Fn.InternalController("SetActivityStatus", {
                                id: b.id,
                                status: e.target.value,
                              }).then((res) => {
                                console.log("SetActivityStatus", res);
                                getActivities(rest.filter);
                                setLoading(false);
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  function StatusIcon({ status }) {
    let icon = <IoWarning />;
    switch (status) {
      case "pending":
        icon = (
          <IconBox
            style={{ marginTop: 5, marginLeft: 4 }}
            as="box"
            h={"30px"}
            w={"30px"}
            bg={iconWarning}
          >
            <ClockIcon
              h={"16px"}
              w={"16px"}
              style={{ marginLeft: 0, marginTop: 0 }}
              color={"white"}
            />
          </IconBox>
        );
        break;
      case "approved":
        icon = (
          <IconBox
            style={{ marginTop: 5, marginLeft: 4 }}
            as="box"
            h={"30px"}
            w={"30px"}
            bg={iconPrimary}
          >
            <FaThumbsUp
              h={"16px"}
              w={"16px"}
              style={{ marginLeft: 0, marginTop: 1 }}
              color={"white"}
            />
          </IconBox>
        );
        break;
      case "declined":
        icon = (
          <IconBox
            style={{ marginTop: 5, marginLeft: 4 }}
            as="box"
            h={"30px"}
            w={"30px"}
            bg={iconRed}
          >
            <FaThumbsDown
              h={"16px"}
              w={"16px"}
              style={{ marginLeft: 0, marginTop: 1 }}
              color={"white"}
            />
          </IconBox>
        );
        break;
      case "denied":
        icon = (
          <IconBox
            style={{ marginTop: 5, marginLeft: 4 }}
            as="box"
            h={"30px"}
            w={"30px"}
            bg={iconRed}
          >
            <RiCloseFill
              h={"16px"}
              w={"16px"}
              style={{ marginLeft: 0, marginTop: 1 }}
              color={"white"}
            />
          </IconBox>
        );
        break;
      case "verified":
        icon = (
          <IconBox
            style={{ marginTop: 5, marginLeft: 4 }}
            as="box"
            h={"30px"}
            w={"30px"}
            bg={iconTeal}
          >
            <CheckCircleIcon
              h={"16px"}
              w={"16px"}
              style={{ marginLeft: 0, marginTop: 1 }}
              color={"white"}
            />
          </IconBox>
        );
        break;
      case "closed":
        icon = (
          <IconBox
            style={{ marginTop: 5, marginLeft: 4 }}
            as="box"
            h={"30px"}
            w={"30px"}
            bg={iconGrey}
          >
            <IoHandRight
              h={"16px"}
              w={"16px"}
              style={{ marginLeft: 0, marginTop: 1 }}
              color={"white"}
            />
          </IconBox>
        );
        break;
      case "error":
        icon = (
          <IconBox
            style={{ marginTop: 5, marginLeft: 4 }}
            as="box"
            h={"30px"}
            w={"30px"}
            bg={textColor}
          >
            <IoWarning
              h={"16px"}
              w={"16px"}
              style={{ marginLeft: 0, marginTop: 0 }}
              color={"white"}
            />
          </IconBox>
        );
        break;
      default:
        icon = (
          <IconBox
            style={{ marginTop: 5, marginLeft: 4 }}
            as="box"
            h={"30px"}
            w={"30px"}
            bg={iconGrey}
          >
            {status.substring(0, 1).toUpperCase()}
          </IconBox>
        );
        break;
    }
    return icon;
  }

  return (
    <Card
      minH="83px"
      style={{
        marginRight: 20,
        minWidth: selected ? 300 : 300,
      }}
    >
      <CardHeader>
        <div style={{ display: "flex", flex: 1 }}>
          <div
            style={{ flex: 1, cursor: "pointer" }}
            onClick={() => {
              showWorkbench();
            }}
          >
            <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
              {selected ? <ChevronRightIcon /> : <ChevronDownIcon />}
              {title}
            </Text>
          </div>
          <div>
            {activityList && (
              <CSVLink
                filename={_.snakeCase(
                  "export_" + title.replace("/", " ").replace(" (Today)", "")
                )}
                data={activityList.map((a) => {
                  return {
                    title: a.title,
                    note: a.note,
                    status: a.status,
                    planned_date: a.planned_date,
                    external_id: a.external_id,
                    lastname: a.lastname,
                    firstname: a.firstname,
                    ins_name: a.ins_name,
                    ins_id: a.ins_id,
                    origin_system_payer_name: a.origin_system_payer_name,
                  };
                })}
              >
                <DownloadIcon />
              </CSVLink>
            )}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div style={{ width: "100%" }}>
          {/* <div style={{ backgroundColor: "#F5F5F5", paddingTop: 8, paddingLeft: 6, borderRadius: 14, marginBottom: 8 }}>
            <Text fontSize='md' color={textColor} fontWeight='bold' pb='.5rem'>
              <div>Valid: {activities?.filter(a => a.status == "approved" || a.status == "verified").length}</div>
            </Text>
          </div> */}
          <div
            style={{
              backgroundColor: "#F5F5F5",
              paddingTop: 2,
              paddingLeft: 6,
              borderRadius: 14,
              marginBottom: 8,
            }}
          >
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              pb=".5rem"
              style={{ display: "flex" }}
            >
              <IconBox
                style={{ marginTop: 5, marginLeft: 4 }}
                as="box"
                h={"30px"}
                w={"30px"}
                bg={iconGrey}
              >
                {activitiesRemaining}
              </IconBox>
              <div
                style={{
                  paddingLeft: 4,
                  paddingTop: 8,
                  fontSize: 10,
                  marginLeft: 4,
                }}
              >
                <div>Require</div>
                <div style={{ marginTop: -4 }}>Attention </div>
              </div>
            </Text>
          </div>
          <div style={{ marginBottom: 6, textAlign: "right" }}>
            <Button
              disabled={!hasPending}
              isLoading={loading}
              onClick={async () => {
                setLoading(true);
                let acts = allActivities?.filter(
                  (a) => a.status.toLowerCase() === "pending"
                );
                console.log(acts);

                if (acts.length !== 0) {
                  let remaining = activitiesRemaining;
                  for (let a of acts) {
                    await Fn.InternalController("VerifyActivity", {
                      id: a.id,
                    })
                      .then(async (res) => {
                        getActivities(rest.filter);
                        return res;
                      })
                      .catch((err) => {
                        console.log("err", err);
                      });
                  }
                } else {
                  // Handle the case where there are no activities
                  console.log("No pending activities.");
                }

                setLoading(false);
              }}
              width="100%"
              variant="outline"
              size="xs"
            >
              Verify Pending
            </Button>
          </div>
          <div>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <Flex direction={"column"}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {activityList &&
                      activityList.length > 0 &&
                      _.orderBy(activityList, "planned_date").map(
                        (a, index) => {
                          return <Activities key={index} activities={a} />;
                        }
                      )}
                  </div>
                </Flex>
              </Stat>
            </Flex>
          </div>
          <div style={{ textAlign: "right", paddingRight: 4 }}>
            <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
              <div>
                Total:{" "}
                <span
                  style={{
                    backgroundColor: rest.incognito ? "black" : "unset",
                    color: rest.incognito ? "black" : "unset",
                  }}
                >
                  {activityList?.length}
                </span>
              </div>
            </Text>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DayStatistics;
