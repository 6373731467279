import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import ContactPopup from './ContactPopup';
import { Box, Button, VStack, Text, useDisclosure, Tr, Td, Table, Thead, Tbody, Th } from '@chakra-ui/react';
import { listCrmContacts } from 'graphql/queries';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import { Icon } from '@aws-amplify/ui-react';
import { FaBuilding, FaUser } from 'react-icons/fa';

const Contacts = () => {
    const [contacts, setContacts] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedContact, setSelectedContact] = useState(null);
    const [isIncognito, setIsIncognito] = useState(false);

    useEffect(() => {
        fetchContacts();
        setIsIncognito(localStorage.getItem('incognito') === 'true');
    }, []);

    const fetchContacts = async () => {
        try {
            const contactData = await API.graphql(graphqlOperation(listCrmContacts));
            setContacts(contactData.data.listCrmContacts.items);
        } catch (err) {
            console.error('Error fetching contacts:', err);
        }
    };

    const handleEdit = (contact) => {
        console.log("contact", contact)
        setSelectedContact(contact);
        onOpen();
    };

    const handleCreate = () => {
        setSelectedContact(null);
        onOpen();
    };

    return (
        <div>
            <Button
                variant={isIncognito ? "solid" : 'outline'}
                size='sm'
                colorScheme="teal"
                onClick={() => {
                    localStorage.setItem('incognito', !isIncognito);
                    setIsIncognito(!isIncognito);
                }}
                mb={4}>
                Set incognito
            </Button>
            <Card >
                <CardHeader>
                    <div style={{ display: "flex", flex: 1 }}>
                        <div style={{ flex: 1 }}>
                            Contacts
                        </div>
                        <div>
                            <Button variant='outline' size='sm' colorScheme="teal" onClick={handleCreate} mb={4}>
                                Add Contact
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table variant='simple' >
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Email</Th>
                                <Th>Phone</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {contacts.map((contact) => (
                                <Tr key={contact.id} onClick={() => handleEdit(contact)}>
                                    <Td variant="nolines" pl="0px" align="center" fontWeight="bold"
                                    ><div style={{ display: "flex" }}>{contact.isCompany ? <FaBuilding /> : <FaUser />} &nbsp; &nbsp;{contact.firstName} {contact.lastName}</div>

                                    </Td>
                                    <Td pl="0px">{contact.email}</Td>
                                    <Td pl="0px">{contact.phone}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </CardBody>
            </Card>
            {
                isOpen && (
                    <ContactPopup
                        isOpen={isOpen}
                        onClose={onClose}
                        contact={selectedContact}
                        onRefresh={fetchContacts}
                    />
                )
            }
        </div >
    );
};

export default Contacts;
