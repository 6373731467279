export const getActivitiesExt = /* GraphQL */ `
  query GetActivities($id: ID!) {
    getActivities(id: $id) {
      id
      title
      note
      status
      planned_day
      planned_date
      external_id
      external_consumer_id
      lastname
      firstname
      ins_id
      dob
      ins_number
      payer_org_id
      origin_system_payer_name
      origin_system_payer_id
      origin_system_payer_code
      origin_system_payer_description
      origin_system_billing_ord
      details
      connectorID
      connectionID
      companyID
      payerID
      activityLogs {
        id
        title
        note
        data
        status
        activity
        companyID
        connectorID
        createdAt
        updatedAt
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const activitiesByPlanned_dayExt = /* GraphQL */ `
  query ActivitiesByPlanned_day(
    $planned_day: String!
    $sortDirection: ModelSortDirection
    $filter: ModelActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activitiesByPlanned_day(
      planned_day: $planned_day
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        planned_date
        planned_day
        external_id
        lastname
        payer_org_id
        origin_system_payer_name
        origin_system_payer_id
        origin_system_payer_code
        origin_system_payer_description
        origin_system_billing_ord
        details
        connectorID
        external_consumer_id
        connectionID
        companyID
        payerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
