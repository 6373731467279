// Chakra imports
import { Badge, Button, Flex, Link, Modal, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ActivityLog from "./components/ActivityLog";
import ActivityDetails from "./components/ActivityDetails";
import moment from "moment";
import { LinkIcon } from "@chakra-ui/icons";
import API from "services/API";
import { set } from "lodash";
import Fn from "services/Fn";
import { useRecoilState } from "recoil";
import { activityState } from "services/states";
import { activityLogState } from "services/states";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ValidSelect from "ui-components/ValidSelect";
import { BsClipboard, BsClipboard2, BsLink } from "react-icons/bs";
import { FaNotesMedical, FaRegStickyNote, FaStickyNote } from "react-icons/fa";
import PayerModal from "./components/PayerModal";

function Activity() {
  const [activity, setActivity] = useRecoilState(activityState);
  const [activity_log, setActivity_log] = useRecoilState(activityLogState);
  const [connection, setConnection] = React.useState([]);
  const [connector, setConnector] = React.useState([]);
  const [settings, setSettings] = React.useState([]);
  const [inputs, setInputs] = React.useState([]);
  const [buttons, setButtons] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [payerSetting, setPayerSetting] = React.useState([]);
  const [details, setDetails] = React.useState();
  const [selectStatus, setSelectStatus] = React.useState();
  const [payerModalOpen, setPayerModalOpen] = React.useState(false);

  const auth = useAuthenticator();


  const { id } = useParams();

  let incognito = localStorage.getItem("incognito") == "true" ? true : false;

  useEffect(() => {
    API.activity(id).then((res) => {
      console.log("activity", res);
      if (res.data.getActivities) {
        let a = res.data.getActivities;
        setActivity(a);
        setDetails(a.details);
        API.connection(a.connectionID).then((res2) => {
          let sets = JSON.parse(res2.data.listConnections.items[0].settings)

          setConnection(res2.data.listConnections.items[0]);
          setSettings(sets);
          console.log("settings", sets);
        })
        API.connector(a.connectorID).then((res3) => {
          console.log("connector", res3);
          setConnector(res3.data.listConnectors.items[0]);
          setInputs(res3.data.listConnectors.items[0].inputs && JSON.parse(res3.data.listConnectors.items[0].inputs));
          let btns = JSON.parse(res3.data.listConnectors.items[0].inputs && res3.data.listConnectors.items[0].inputs);
          console.log("btns", btns.filter((b) => { return b.key.includes("act.btn") }));
          setButtons(btns.filter((b) => { return b.key.includes("act.btn") }));
        })
        API.payerSettings().then((res4) => {
          console.log("payers", res4);
          let payer = res4.data.listPayerSettings.items.find(x => a.payerID == x.external_id)
          console.log("payer", payer);
          setPayerSetting(payer);
        })

      }

    })
    return () => {
      setActivity(null);
      setActivity_log([]);
    }
  }, []);


  let textColor = useColorModeValue("gray.700", "white");
  let statusTextColor = useColorModeValue("gray.700", "white");
  let statusColor = useColorModeValue("gray.400", "gray.400");

  if (activity && activity.status && activity?.status.toLowerCase() === "verified") {
    statusColor = useColorModeValue("teal.400", "teal.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (activity && activity.status && activity?.status.toLowerCase() === "pending") {
    statusColor = useColorModeValue("yellow.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (activity && activity.status && activity.status.toLowerCase() === "rejected") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (activity && activity.status && activity?.status.toLowerCase() === "denied") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (activity && activity.status && activity?.status.toLowerCase() === "error") {
    statusColor = useColorModeValue("black", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (activity && activity.status && activity?.status.toLowerCase() === "declined") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  } else if (activity && activity.status && activity?.status.toLowerCase() === "denied") {
    statusColor = useColorModeValue("red.400", "gray.400");
    statusTextColor = useColorModeValue("white", "white");
  }

  let statusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Decline ", value: "declined" },
    { label: "Approved*", value: "approved" },
    { label: "Closed*", value: "closed" },
    { label: "Error", value: "error" },
  ]

  if (process.env.REACT_APP_ENV_NAME == "staging") {
    statusOptions = [
      { label: "Pending", value: "test-pending" },
      { label: "Decline ", value: "test-declined" },
      { label: "Approved*", value: "test-approved" },
      { label: "Closed*", value: "test-closed" },
      { label: "Error", value: "test-error" },
      { label: "Verified", value: "test-verified" },
    ]
  }



  return (
    <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
      <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p='6px 0px 22px 0px'>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", display: "flex" }}>

              <Flex direction='column' flex={1}>
                <Text fontSize='lg' fontWeight='bold' pb='.5rem'>
                  {activity?.title}
                </Text>

              </Flex>
              {loading && (
                <Flex style={{ marginLeft: 12 }} >
                  <Spinner size="sm" />
                </Flex>
              )}
              {settings && buttons && buttons.map((btn) => {
                return (
                  <>
                    {settings[btn.key] && (
                      <Flex style={{ marginLeft: 12 }} >
                        <a href={settings[btn.key].replace("{consumer_id}", activity?.external_consumer_id).replace("{id}", activity?.id)} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                          <Button size="sm" variant="outline" colorScheme='grey' leftIcon={<LinkIcon />}>{btn.label}</Button></a>
                      </Flex>
                    )}
                  </>
                )
              })}

              {activity && activity?.status == "pending" && (
                <Flex style={{ marginLeft: 12 }} >
                  <Button size="sm"
                    disabled={loading || activity?.lastname.includes("XXX") || payerSetting?.status?.toLowerCase() != "active"}
                    variant="outline"
                    colorScheme='teal'
                    leftIcon={<LinkIcon />}
                    onClick={() => {
                      setLoading(true);
                      Fn.InternalController("RunConnection", {
                        id: activity.connectionID,
                        external_id: activity?.external_id,
                      }).then(res => {

                        Fn.InternalController("VerifyActivity", { id: activity.id }).then(res => {
                          console.log("VerifyActivity", res);
                          API.activity(id).then((res2) => {
                            let a = res2.data.getActivities;
                            console.log("activity", res2);
                            setActivity(a);
                            setDetails(a.details);
                            API.activity_log(id).then((res3) => {
                              console.log("activity_log", res3);
                              setActivity_log(res3);
                              setLoading(false);
                            })
                          })
                        }).catch(err => {
                          setLoading(false);
                        })
                      }).catch(err => {
                        setLoading(false);
                      })
                    }}

                  >Verify Now</Button>
                </Flex>
              )}



              {auth.user && auth.user.attributes && auth.user.attributes["custom:global-admin"] == "true" && (
                <Flex style={{ marginLeft: 12 }} >
                  {/* <Button size="sm"
                    disabled={loading || activity?.lastname.includes("XXX")}
                    variant="outline"
                    colorScheme='teal'
                    leftIcon={<LinkIcon />}
                    onClick={() => {
                      setLoading(true);
                      Fn.InternalController("RunConnection", {
                        id: activity.connectionID,
                        external_id: activity?.external_id,
                      }).then(res => {
                        Fn.InternalController("VerifyActivity", { id: activity.id }).then(res => {
                          console.log("VerifyActivity", res);
                          API.activity(id).then((res2) => {
                            let a = res2.data.getActivities;
                            console.log("activity", res2);
                            setActivity(a);
                            setDetails(a.details);
                            API.activity_log(id).then((res3) => {
                              console.log("activity_log", res3);
                              setActivity_log(res3);
                              setLoading(false);
                            })
                          })
                        }).catch(err => {
                          setLoading(false);
                        })
                      }).catch(err => {
                        setLoading(false);
                      })
                    }}
                  >Force Verify</Button> */}
                </Flex>

              )}
            </div>

            <div style={{ flex: 1, display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Text fontSize='sm' color='gray.500'>
                  ACTIVITY ID:  <span style={{ backgroundColor: incognito ? "black" : "unset", color: incognito ? "black" : "unset" }}>{activity?.external_id}</span><br />
                  CLIENT ID:  <span style={{ backgroundColor: incognito ? "black" : "unset", color: incognito ? "black" : "unset" }}>{activity?.external_consumer_id}</span><br />
                </Text>
              </div>
              <div style={{ paddingRight: 14, paddingTop: 6 }}>
                {payerSetting && payerSetting.status && payerSetting?.status?.toLowerCase() != "active" && (
                  <div style={{ paddingBottom: 6 }}>
                    <Text fontSize='sm' color='red.500' fontWeight='bold'>
                      {payerSetting?.name} is {payerSetting?.status}
                    </Text>
                  </div>
                )}



                {activity && activity.lastname && activity?.lastname.includes("XXX") && (
                  <div style={{ paddingBottom: 6 }}>
                    <Text fontSize='sm' color='red.500' fontWeight='bold'>
                      Patient information is private and must be manually verified
                    </Text>
                  </div>
                )}
                {process.env.REACT_APP_ENV_NAME != "staging" && activity && activity.payerID && !payerSetting && (
                  <div style={{ paddingBottom: 6 }}>
                    <Text fontSize='sm' color='red.500' fontWeight='bold'>
                      Payer id {activity?.payerID} is not connected
                    </Text>
                  </div>
                )}

                {activity && (
                  <div >
                    <ValidSelect
                      label={""}
                      description={""}
                      value={selectStatus}
                      placeholder={"Set Status"}
                      options={statusOptions}
                      onChange={(e) => {
                        setSelectStatus(e.target.value);
                        Fn.InternalController("SetActivityStatus", { id: id, status: e.target.value }).then(res => {
                          console.log("SetActivityStatus", res);
                          API.activity(id).then((res2) => {
                            let a = res2.data.getActivities;
                            console.log("activity", res2);
                            setActivity(a);
                            setDetails(a.details);
                            API.activity_log(id).then((res3) => {
                              console.log("activity_log", res3);
                              setActivity_log(res3);
                              setLoading(false);
                            })
                          })
                        })
                      }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <Flex style={{ flex: 1 }}>
                <div style={{ flex: 1, paddingRight: 6 }}>
                  <div style={{ display: "flex" }}>
                    <Text fontSize='lg' fontWeight='bold' pb='.5rem'>Payer</Text>
                    {payerSetting && payerSetting.notes && (
                      <>
                        <div style={{ padding: 4 }} onClick={() => {
                          setPayerModalOpen(true)
                        }}>
                          <FaRegStickyNote />
                        </div>
                        <PayerModal
                          title={activity && activity.origin_system_payer_name ? activity?.origin_system_payer_name : activity?.origin_system_payer_code}
                          note={payerSetting?.notes}
                          isOpen={payerModalOpen}
                          onClose={() => {
                            console.log("onClose")
                            setPayerModalOpen(false)
                          }} />
                      </>
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <span style={{ backgroundColor: incognito ? "black" : "unset", color: incognito ? "black" : "unset" }}>{activity && activity.origin_system_payer_name ? activity?.origin_system_payer_name : activity?.origin_system_payer_code}</span>
                    <div style={{ padding: 4 }}>
                      {payerSetting && payerSetting?.url && (
                        <a href={payerSetting && payerSetting?.url} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                          <BsLink />
                        </a>
                      )}
                    </div>
                  </div>
                </div>

              </Flex>
              <Flex style={{ flex: 1 }}>
                <div style={{ flex: 1, }}>
                  <Text fontSize='lg' fontWeight='bold' pb='.5rem'>Consumer</Text>
                  <span style={{ backgroundColor: incognito ? "black" : "unset", color: incognito ? "black" : "unset" }}>{activity?.lastname}, {activity?.firstname}</span>
                </div>

              </Flex>
              <Flex style={{ flex: 1 }}>
                <div style={{ flex: 1 }}>
                  <Text fontSize='lg' fontWeight='bold' pb='.5rem'>Created At</Text>
                  <div>{moment(activity?.createdAt).format("h:mm a")}</div>
                  <div style={{ fontSize: "small", fontWeight: "bold" }}>{moment(activity?.createdAt).format("MMM Do YYYY")}</div>
                </div>
              </Flex>
              <Flex style={{ flex: 1 }}>
                <div style={{ flex: 1 }}>
                  <Text fontSize='lg' fontWeight='bold' pb='.5rem'>Planned Date</Text>
                  <div ><span style={{ backgroundColor: incognito ? "black" : "unset", color: incognito ? "black" : "unset" }}>{moment(activity?.planned_date).format("h:mm a")}</span></div>
                  <div style={{ fontSize: "small", fontWeight: "bold" }}><span style={{ backgroundColor: incognito ? "black" : "unset", color: incognito ? "black" : "unset" }}>{moment(activity?.planned_date).format("MMM Do YYYY")}</span></div>
                </div>
              </Flex>
              <Flex style={{ flex: 1 }}>
                <div style={{ flex: 1 }}>
                  <Text fontSize='lg' fontWeight='bold' pb='.5rem'>Status</Text>
                  <Badge
                    bg={statusColor}
                    color={statusTextColor}
                    fontSize="16px"
                    p="3px 10px"
                    borderRadius="8px"
                  >
                    {activity?.status}
                  </Badge>
                </div>
              </Flex>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <Flex style={{ flex: 1 }}>
                <div style={{ flex: 1 }}>
                  <Text fontSize='lg' fontWeight='bold' pb='.5rem'>Note</Text>
                  {activity?.note}
                </div>
              </Flex>
            </div>
          </div>
        </CardBody>
      </Card>
      <Flex>
        <div style={{
          paddingRight: 10,
          flex: 1
        }}>
          {loading != true && activity && details && (
            <ActivityDetails
              title={"Activity"}
              captions={["Activity", "Date", "Status"]}
              details={details}
              id={id}
            />)}
        </div>
        <div style={{
          paddingLeft: 10,
          flex: 1
        }}>
          {activity && activity.id && (
            <ActivityLog
              title={"Activity"}
              captions={["Log", "Created At", "Status"]}
              id={activity?.id}
            />)}</div></Flex>

    </Flex >
  );
}

export default Activity;
