/*eslint-disable*/
// chakra imports
import {
  Box, Button, useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import SidebarContent from "./SidebarContent";


// FUNCTIONS

function CrmSidebar(props) {
  // to check for active links and opened collapses
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";

  const { logoText, routes, sidebarVariant } = props;

  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  if (sidebarVariant === "opaque") {
    sidebarBg = useColorModeValue("white", "gray.700");
    sidebarRadius = "16px";
    sidebarMargins = "16px 0px 16px 16px";
  }

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
          <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div style={{ flex: 1 }}>
              <SidebarContent routes={routes}
                logoText={"VALID DASHBOARD"}
                display="none"
                sidebarVariant={sidebarVariant}
              />
            </div>
            <div style={{ paddingBottom: 20 }}>
              <Button
                variant="outline"
                onClick={() => {
                  window.location.href = "#"
                }}
                width={"100%"} size="sm" >Valid</Button>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
}




export default CrmSidebar;
