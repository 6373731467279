// Chakra imports
import {
  Avatar,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { functions, set } from "lodash";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { teamState } from "services/states";
import { Bucket } from "services/storage";
import TeamMember from "./TeamMember";
import API from "services/API";
import ValidInput from "ui-components/ValidInput";
import Fn from "services/Fn";

const Team = ({ title }) => {
  const [team, setTeam] = useRecoilState(teamState);
  const [newUserEmail, setNewUserEmail] = React.useState();
  const [newUserName, setNewUserName] = React.useState();
  const [showNewUser, setShowNewUser] = React.useState(false);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    if (!team) {
      API.users().then((res) => {
        setTeam(_.filter(res.data.listUsers.items, (item) => item.active != false));
      }
      )
    }
  }, []);

  return (
    <Card p='16px'>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ width: "100%" }}>
            <Flex direction='column' w='100%'>
              {team && team.length > 0 ? team.map((member, index) => (
                <TeamMember
                  key={member.id}
                  name={member.name}
                  email={member.email}
                  role={member.current_role_id?.name}
                  image={member.image}
                  id={member.id}
                  active={member.active} />
              )) : <Text fontSize='md' color='gray.500' fontWeight='400'>
                No team members yet
              </Text>}
            </Flex>
          </div>
          <div style={{}}>
            {showNewUser && (
              <>
                <ValidInput label="*Email" placeholder='Enter Email' value={newUserEmail}
                  onChange={(e) => {
                    setNewUserEmail(e.target.value)
                  }} />
                <ValidInput label="*Name" placeholder='Enter Name' value={newUserName}
                  onChange={(e) => {
                    setNewUserName(e.target.value)
                  }} /></>)}
            <Flex style={{ marginLeft: "auto" }}>
              {showNewUser ? (
                <>
                  <Button
                    size="sm"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setShowNewUser(false);
                    }}
                    mt='14px' variant='outline' colorScheme='grey'>
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: 16 }}
                    size="sm"
                    onClick={() => {
                      if (!showNewUser) {
                        setShowNewUser(true);
                      } else {
                        Fn.InternalController("PostUser", { email: newUserEmail, name: newUserName }).then(res => {
                          console.log("res", { email: newUserEmail, name: newUserName });
                          setTeam([...team, {}]);
                          setNewUserEmail(null);
                          setNewUserName(null);
                          alert("Invite sent");
                          setShowNewUser(false);
                        }
                        )
                      }
                    }}
                    disabled={!newUserEmail || !newUserName}
                    mt='14px' colorScheme='green'>
                    Send Invite
                  </Button>
                </>) : (
                <Button
                  size="sm"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    setShowNewUser(true);
                  }}
                  mt='14px'
                  variant='outline'
                  colorScheme='teal'>
                  Add Team Member
                </Button>
              )}
            </Flex>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Team;
