import { Input, Text } from "@chakra-ui/react";
import React from "react";

export default function ValidChip({ label, color }) {
  return (
    <div
      style={{
        padding: 4,
        backgroundColor: color ? color : "grey",
        borderRadius: 30,
        margin: 4,
        fontSize: 12,
        fontWeight: 900,
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
      }}
    >
      {label}
    </div>
  );
}
