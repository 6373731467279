// Chakra imports
import { Button, Flex, Icon, Input, Link, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaRemoveFormat, FaTwitter, FaXing } from "react-icons/fa";
import { CompanyProfile } from "./CompanyProfile";
import ValidInput from "ui-components/ValidInput";
import Fn from "services/Fn";
import { useRecoilState } from "recoil";
import { companyState } from "services/states";
import Store from "services/Store";
import { CloseIcon } from "@chakra-ui/icons";
import { set } from "lodash";

const ProfileInformation = ({
  title,
  image,
  name,
  primary_phone,
  address,
  city,
  state,
  postal_code,
  npi,
  tax_id,
}) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [inputName, setInputName] = useState(name);
  const [inputPhone, setInputPhone] = useState(primary_phone);
  const [inputAddress, setInputAddress] = useState(address);
  const [inputCity, setInputCity] = useState(city);
  const [inputState, setInputState] = useState(state);
  const [inputPostalCode, setInputPostalCode] = useState(postal_code);
  const [company, setCompany] = useRecoilState(companyState);
  const [showUpload, setShowUpload] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [inputNpi, setInputNpi] = useState(company.npi);
  const [taxInputId, setInputTaxId] = useState(company.tax_id);

  useEffect(() => {
    // TODO: add in company logo
    // if (company && company.id && !company.logo) {
    //   Store.imageUrl(company.id + "/logo").then((res) => {
    //     console.log("res image", res);
    //     setCompany({ ...company, logo: res });
    //   })
    // }
  }, []);

  return (
    <Card p='16px' my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column' width={"100%"}>
          {/* TODO: add in company logo */}
          {/* <div style={{ paddingBottom: 24 }}>
            {company && company.logo ? (
              <div style={{ width: "fit-content", borderRadius: 10, position: "relative", }}>
                <div
                  onClick={() => {
                    setCompany({ ...company, logo: null });
                  }}
                  style={{
                    position: "absolute",
                    top: -6,
                    left: -6,
                    backgroundColor: "#ccc",
                    width: 20,
                    height: 20,
                    borderRadius: 16,
                    fontSize: 10,
                    textAlign: "center",
                    border: "2px white solid",
                  }} >
                  <Icon as={CloseIcon} color="white" cursor="pointer" />
                </div>
                <img src={company.logo} alt='profile' style={{ maxHeight: 100, borderRadius: 10, border: "2px #ccc solid", }} />
              </div>) : (
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <input
                    type="file"
                    onChange={(e) => {
                      setImageLoading(true);
                      console.log("e", e.target.files[0]);
                      Store.image(company.id + "/logo", e.target.files[0]).then((res) => {
                        Store.imageUrl(company.id + "/logo").then((res) => {
                          console.log("res image", res);
                          setCompany({ ...company, logo: res });
                          Fn.InternalController("PutCompany", { ...company, logo: res }).then((res) => {
                            setImageLoading(false);
                          })
                        })
                      });
                    }} />
                </div>
                <div>
                  {imageLoading && <Spinner />}
                </div>
              </div>
            )}
          </div> */}
          <ValidInput label="Company Name" value={inputName} onChange={(e) => {
            setInputName(e.target.value);
          }} />
          <ValidInput label="Phone" value={inputPhone} onChange={(e) => {
            setInputPhone(e.target.value);
          }} />
          <ValidInput label="Address" value={inputAddress} onChange={(e) => {
            setInputAddress(e.target.value);
          }} />
          <ValidInput label="City" value={inputCity} onChange={(e) => {
            setInputCity(e.target.value);
          }} />
          <ValidInput label="State" value={inputState} onChange={(e) => {
            setInputState(e.target.value);
          }} />
          <ValidInput label="Postal Code" value={inputPostalCode} onChange={(e) => {
            setInputPostalCode(e.target.value);
          }} />
          <hr style={{ marginTop: 16, marginBottom: 16 }} />
          <ValidInput label="NPI" value={inputNpi} onChange={(e) => {
            setInputNpi(e.target.value);
          }} />
          <ValidInput label="Tax ID" value={taxInputId} onChange={(e) => {
            setInputTaxId(e.target.value);
          }} />




          <div style={{ paddingTop: 24 }}>
            <Button
              disabled={inputName === name &&
                inputPhone === primary_phone &&
                inputAddress === address &&
                inputCity === city &&
                inputState === state &&
                inputPostalCode === postal_code &&
                inputNpi === company.npi &&
                taxInputId === company.tax_id}
              onClick={() => {
                setCompany({
                  ...company,
                  name: inputName,
                  primary_phone: inputPhone,
                  address: inputAddress,
                  city: inputCity,
                  state: inputState,
                  postal_code: inputPostalCode,
                  npi: inputNpi,
                  tax_id: taxInputId,
                });
                console.log("save");
                Fn.InternalController("PutCompany", {
                  ...company,
                  name: inputName,
                  primary_phone: inputPhone,
                  address: inputAddress,
                  city: inputCity,
                  state: inputState,
                  postal_code: inputPostalCode,
                  npi: inputNpi,
                  tax_id: taxInputId,
                }).then((res) => {
                  console.log("res", res);
                  setCompany({ ...company, ...res });
                });
              }}
              width="100%" >
              Save
            </Button>
          </div>

        </Flex>
      </CardBody>
      {/* <CompanyProfile
        show={showUpload}
        onClose={() => {
          setShowUpload(false);
        }}
      /> */}
    </Card >
  );
};

export default ProfileInformation;
