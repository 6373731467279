/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addMockingData = /* GraphQL */ `
  mutation AddMockingData($input: BoxInput) {
    addMockingData(input: $input)
  }
`;
export const InternalController = /* GraphQL */ `
  mutation InternalController($input: InternalControllerInput) {
    InternalController(input: $input)
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      image
      name
      email
      auth_id
      active
      owner
      tenant
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      image
      name
      email
      auth_id
      active
      owner
      tenant
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      image
      name
      email
      auth_id
      active
      owner
      tenant
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPayers = /* GraphQL */ `
  mutation CreatePayers(
    $input: CreatePayersInput!
    $condition: ModelPayersConditionInput
  ) {
    createPayers(input: $input, condition: $condition) {
      id
      name
      type
      use_dob
      use_ins_number
      external_id
      verify_id
      url
      notes
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayers = /* GraphQL */ `
  mutation UpdatePayers(
    $input: UpdatePayersInput!
    $condition: ModelPayersConditionInput
  ) {
    updatePayers(input: $input, condition: $condition) {
      id
      name
      type
      use_dob
      use_ins_number
      external_id
      verify_id
      url
      notes
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayers = /* GraphQL */ `
  mutation DeletePayers(
    $input: DeletePayersInput!
    $condition: ModelPayersConditionInput
  ) {
    deletePayers(input: $input, condition: $condition) {
      id
      name
      type
      use_dob
      use_ins_number
      external_id
      verify_id
      url
      notes
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPayerSetting = /* GraphQL */ `
  mutation CreatePayerSetting(
    $input: CreatePayerSettingInput!
    $condition: ModelPayerSettingConditionInput
  ) {
    createPayerSetting(input: $input, condition: $condition) {
      id
      name
      type
      payer_org_id
      external_id
      state
      url
      notes
      status
      route
      companyID
      payerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayerSetting = /* GraphQL */ `
  mutation UpdatePayerSetting(
    $input: UpdatePayerSettingInput!
    $condition: ModelPayerSettingConditionInput
  ) {
    updatePayerSetting(input: $input, condition: $condition) {
      id
      name
      type
      payer_org_id
      external_id
      state
      url
      notes
      status
      route
      companyID
      payerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayerSetting = /* GraphQL */ `
  mutation DeletePayerSetting(
    $input: DeletePayerSettingInput!
    $condition: ModelPayerSettingConditionInput
  ) {
    deletePayerSetting(input: $input, condition: $condition) {
      id
      name
      type
      payer_org_id
      external_id
      state
      url
      notes
      status
      route
      companyID
      payerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConnectors = /* GraphQL */ `
  mutation CreateConnectors(
    $input: CreateConnectorsInput!
    $condition: ModelConnectorsConditionInput
  ) {
    createConnectors(input: $input, condition: $condition) {
      id
      logo
      name
      url
      category
      status
      description
      details
      slug
      frame
      inputs
      meta
      activityLogs {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConnectors = /* GraphQL */ `
  mutation UpdateConnectors(
    $input: UpdateConnectorsInput!
    $condition: ModelConnectorsConditionInput
  ) {
    updateConnectors(input: $input, condition: $condition) {
      id
      logo
      name
      url
      category
      status
      description
      details
      slug
      frame
      inputs
      meta
      activityLogs {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConnectors = /* GraphQL */ `
  mutation DeleteConnectors(
    $input: DeleteConnectorsInput!
    $condition: ModelConnectorsConditionInput
  ) {
    deleteConnectors(input: $input, condition: $condition) {
      id
      logo
      name
      url
      category
      status
      description
      details
      slug
      frame
      inputs
      meta
      activityLogs {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConnections = /* GraphQL */ `
  mutation CreateConnections(
    $input: CreateConnectionsInput!
    $condition: ModelConnectionsConditionInput
  ) {
    createConnections(input: $input, condition: $condition) {
      id
      name
      logo
      status
      meta
      schedule
      settings
      companyID
      connectorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConnections = /* GraphQL */ `
  mutation UpdateConnections(
    $input: UpdateConnectionsInput!
    $condition: ModelConnectionsConditionInput
  ) {
    updateConnections(input: $input, condition: $condition) {
      id
      name
      logo
      status
      meta
      schedule
      settings
      companyID
      connectorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConnections = /* GraphQL */ `
  mutation DeleteConnections(
    $input: DeleteConnectionsInput!
    $condition: ModelConnectionsConditionInput
  ) {
    deleteConnections(input: $input, condition: $condition) {
      id
      name
      logo
      status
      meta
      schedule
      settings
      companyID
      connectorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanies = /* GraphQL */ `
  mutation CreateCompanies(
    $input: CreateCompaniesInput!
    $condition: ModelCompaniesConditionInput
  ) {
    createCompanies(input: $input, condition: $condition) {
      id
      name
      address
      city
      state
      postal_code
      primary_phone
      primary_email
      logo
      npi
      tax_id
      consumers {
        nextToken
        __typename
      }
      crmContacts {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      connectors {
        nextToken
        __typename
      }
      requirements {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanies = /* GraphQL */ `
  mutation UpdateCompanies(
    $input: UpdateCompaniesInput!
    $condition: ModelCompaniesConditionInput
  ) {
    updateCompanies(input: $input, condition: $condition) {
      id
      name
      address
      city
      state
      postal_code
      primary_phone
      primary_email
      logo
      npi
      tax_id
      consumers {
        nextToken
        __typename
      }
      crmContacts {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      connectors {
        nextToken
        __typename
      }
      requirements {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanies = /* GraphQL */ `
  mutation DeleteCompanies(
    $input: DeleteCompaniesInput!
    $condition: ModelCompaniesConditionInput
  ) {
    deleteCompanies(input: $input, condition: $condition) {
      id
      name
      address
      city
      state
      postal_code
      primary_phone
      primary_email
      logo
      npi
      tax_id
      consumers {
        nextToken
        __typename
      }
      crmContacts {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      users {
        nextToken
        __typename
      }
      connectors {
        nextToken
        __typename
      }
      requirements {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRequirements = /* GraphQL */ `
  mutation CreateRequirements(
    $input: CreateRequirementsInput!
    $condition: ModelRequirementsConditionInput
  ) {
    createRequirements(input: $input, condition: $condition) {
      id
      name
      description
      status
      link
      link_text
      icon
      meta
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRequirements = /* GraphQL */ `
  mutation UpdateRequirements(
    $input: UpdateRequirementsInput!
    $condition: ModelRequirementsConditionInput
  ) {
    updateRequirements(input: $input, condition: $condition) {
      id
      name
      description
      status
      link
      link_text
      icon
      meta
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRequirements = /* GraphQL */ `
  mutation DeleteRequirements(
    $input: DeleteRequirementsInput!
    $condition: ModelRequirementsConditionInput
  ) {
    deleteRequirements(input: $input, condition: $condition) {
      id
      name
      description
      status
      link
      link_text
      icon
      meta
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConsumers = /* GraphQL */ `
  mutation CreateConsumers(
    $input: CreateConsumersInput!
    $condition: ModelConsumersConditionInput
  ) {
    createConsumers(input: $input, condition: $condition) {
      id
      meta
      companyID
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConsumers = /* GraphQL */ `
  mutation UpdateConsumers(
    $input: UpdateConsumersInput!
    $condition: ModelConsumersConditionInput
  ) {
    updateConsumers(input: $input, condition: $condition) {
      id
      meta
      companyID
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConsumers = /* GraphQL */ `
  mutation DeleteConsumers(
    $input: DeleteConsumersInput!
    $condition: ModelConsumersConditionInput
  ) {
    deleteConsumers(input: $input, condition: $condition) {
      id
      meta
      companyID
      companies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCredits = /* GraphQL */ `
  mutation CreateCredits(
    $input: CreateCreditsInput!
    $condition: ModelCreditsConditionInput
  ) {
    createCredits(input: $input, condition: $condition) {
      id
      name
      type
      amount
      status
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCredits = /* GraphQL */ `
  mutation UpdateCredits(
    $input: UpdateCreditsInput!
    $condition: ModelCreditsConditionInput
  ) {
    updateCredits(input: $input, condition: $condition) {
      id
      name
      type
      amount
      status
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCredits = /* GraphQL */ `
  mutation DeleteCredits(
    $input: DeleteCreditsInput!
    $condition: ModelCreditsConditionInput
  ) {
    deleteCredits(input: $input, condition: $condition) {
      id
      name
      type
      amount
      status
      companyID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActivityLog = /* GraphQL */ `
  mutation CreateActivityLog(
    $input: CreateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    createActivityLog(input: $input, condition: $condition) {
      id
      title
      note
      data
      status
      activity
      companyID
      connectorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateActivityLog = /* GraphQL */ `
  mutation UpdateActivityLog(
    $input: UpdateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    updateActivityLog(input: $input, condition: $condition) {
      id
      title
      note
      data
      status
      activity
      companyID
      connectorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteActivityLog = /* GraphQL */ `
  mutation DeleteActivityLog(
    $input: DeleteActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    deleteActivityLog(input: $input, condition: $condition) {
      id
      title
      note
      data
      status
      activity
      companyID
      connectorID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActivities = /* GraphQL */ `
  mutation CreateActivities(
    $input: CreateActivitiesInput!
    $condition: ModelActivitiesConditionInput
  ) {
    createActivities(input: $input, condition: $condition) {
      id
      title
      note
      status
      planned_date
      planned_day
      external_id
      external_consumer_id
      lastname
      firstname
      ins_id
      dob
      ins_number
      payer_org_id
      origin_system_payer_name
      origin_system_payer_id
      origin_system_payer_code
      origin_system_payer_description
      origin_system_billing_ord
      details
      connectorID
      connectionID
      companyID
      payerID
      activityLogs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateActivities = /* GraphQL */ `
  mutation UpdateActivities(
    $input: UpdateActivitiesInput!
    $condition: ModelActivitiesConditionInput
  ) {
    updateActivities(input: $input, condition: $condition) {
      id
      title
      note
      status
      planned_date
      planned_day
      external_id
      external_consumer_id
      lastname
      firstname
      ins_id
      dob
      ins_number
      payer_org_id
      origin_system_payer_name
      origin_system_payer_id
      origin_system_payer_code
      origin_system_payer_description
      origin_system_billing_ord
      details
      connectorID
      connectionID
      companyID
      payerID
      activityLogs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteActivities = /* GraphQL */ `
  mutation DeleteActivities(
    $input: DeleteActivitiesInput!
    $condition: ModelActivitiesConditionInput
  ) {
    deleteActivities(input: $input, condition: $condition) {
      id
      title
      note
      status
      planned_date
      planned_day
      external_id
      external_consumer_id
      lastname
      firstname
      ins_id
      dob
      ins_number
      payer_org_id
      origin_system_payer_name
      origin_system_payer_id
      origin_system_payer_code
      origin_system_payer_description
      origin_system_billing_ord
      details
      connectorID
      connectionID
      companyID
      payerID
      activityLogs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCrmContact = /* GraphQL */ `
  mutation CreateCrmContact(
    $input: CreateCrmContactInput!
    $condition: ModelCrmContactConditionInput
  ) {
    createCrmContact(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      isCompany
      meta
      notes
      companyID
      company {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      interactions {
        nextToken
        __typename
      }
      lists {
        nextToken
        __typename
      }
      relatedContacts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCrmContact = /* GraphQL */ `
  mutation UpdateCrmContact(
    $input: UpdateCrmContactInput!
    $condition: ModelCrmContactConditionInput
  ) {
    updateCrmContact(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      isCompany
      meta
      notes
      companyID
      company {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      interactions {
        nextToken
        __typename
      }
      lists {
        nextToken
        __typename
      }
      relatedContacts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCrmContact = /* GraphQL */ `
  mutation DeleteCrmContact(
    $input: DeleteCrmContactInput!
    $condition: ModelCrmContactConditionInput
  ) {
    deleteCrmContact(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phone
      isCompany
      meta
      notes
      companyID
      company {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      interactions {
        nextToken
        __typename
      }
      lists {
        nextToken
        __typename
      }
      relatedContacts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCrmContactConnection = /* GraphQL */ `
  mutation CreateCrmContactConnection(
    $input: CreateCrmContactConnectionInput!
    $condition: ModelCrmContactConnectionConditionInput
  ) {
    createCrmContactConnection(input: $input, condition: $condition) {
      id
      contactID
      relatedContactID
      contact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      relatedContact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCrmContactConnection = /* GraphQL */ `
  mutation UpdateCrmContactConnection(
    $input: UpdateCrmContactConnectionInput!
    $condition: ModelCrmContactConnectionConditionInput
  ) {
    updateCrmContactConnection(input: $input, condition: $condition) {
      id
      contactID
      relatedContactID
      contact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      relatedContact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCrmContactConnection = /* GraphQL */ `
  mutation DeleteCrmContactConnection(
    $input: DeleteCrmContactConnectionInput!
    $condition: ModelCrmContactConnectionConditionInput
  ) {
    deleteCrmContactConnection(input: $input, condition: $condition) {
      id
      contactID
      relatedContactID
      contact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      relatedContact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCrmAddress = /* GraphQL */ `
  mutation CreateCrmAddress(
    $input: CreateCrmAddressInput!
    $condition: ModelCrmAddressConditionInput
  ) {
    createCrmAddress(input: $input, condition: $condition) {
      id
      street
      city
      state
      postalCode
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCrmAddress = /* GraphQL */ `
  mutation UpdateCrmAddress(
    $input: UpdateCrmAddressInput!
    $condition: ModelCrmAddressConditionInput
  ) {
    updateCrmAddress(input: $input, condition: $condition) {
      id
      street
      city
      state
      postalCode
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCrmAddress = /* GraphQL */ `
  mutation DeleteCrmAddress(
    $input: DeleteCrmAddressInput!
    $condition: ModelCrmAddressConditionInput
  ) {
    deleteCrmAddress(input: $input, condition: $condition) {
      id
      street
      city
      state
      postalCode
      country
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCrmInteraction = /* GraphQL */ `
  mutation CreateCrmInteraction(
    $input: CreateCrmInteractionInput!
    $condition: ModelCrmInteractionConditionInput
  ) {
    createCrmInteraction(input: $input, condition: $condition) {
      id
      crmContactID
      type
      date
      notes
      details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCrmInteraction = /* GraphQL */ `
  mutation UpdateCrmInteraction(
    $input: UpdateCrmInteractionInput!
    $condition: ModelCrmInteractionConditionInput
  ) {
    updateCrmInteraction(input: $input, condition: $condition) {
      id
      crmContactID
      type
      date
      notes
      details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCrmInteraction = /* GraphQL */ `
  mutation DeleteCrmInteraction(
    $input: DeleteCrmInteractionInput!
    $condition: ModelCrmInteractionConditionInput
  ) {
    deleteCrmInteraction(input: $input, condition: $condition) {
      id
      crmContactID
      type
      date
      notes
      details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCrmList = /* GraphQL */ `
  mutation CreateCrmList(
    $input: CreateCrmListInput!
    $condition: ModelCrmListConditionInput
  ) {
    createCrmList(input: $input, condition: $condition) {
      id
      contacts {
        nextToken
        __typename
      }
      type
      date
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCrmList = /* GraphQL */ `
  mutation UpdateCrmList(
    $input: UpdateCrmListInput!
    $condition: ModelCrmListConditionInput
  ) {
    updateCrmList(input: $input, condition: $condition) {
      id
      contacts {
        nextToken
        __typename
      }
      type
      date
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCrmList = /* GraphQL */ `
  mutation DeleteCrmList(
    $input: DeleteCrmListInput!
    $condition: ModelCrmListConditionInput
  ) {
    deleteCrmList(input: $input, condition: $condition) {
      id
      contacts {
        nextToken
        __typename
      }
      type
      date
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompaniesConnectors = /* GraphQL */ `
  mutation CreateCompaniesConnectors(
    $input: CreateCompaniesConnectorsInput!
    $condition: ModelCompaniesConnectorsConditionInput
  ) {
    createCompaniesConnectors(input: $input, condition: $condition) {
      id
      connectorsId
      companiesId
      connectors {
        id
        logo
        name
        url
        category
        status
        description
        details
        slug
        frame
        inputs
        meta
        createdAt
        updatedAt
        __typename
      }
      companies {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompaniesConnectors = /* GraphQL */ `
  mutation UpdateCompaniesConnectors(
    $input: UpdateCompaniesConnectorsInput!
    $condition: ModelCompaniesConnectorsConditionInput
  ) {
    updateCompaniesConnectors(input: $input, condition: $condition) {
      id
      connectorsId
      companiesId
      connectors {
        id
        logo
        name
        url
        category
        status
        description
        details
        slug
        frame
        inputs
        meta
        createdAt
        updatedAt
        __typename
      }
      companies {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompaniesConnectors = /* GraphQL */ `
  mutation DeleteCompaniesConnectors(
    $input: DeleteCompaniesConnectorsInput!
    $condition: ModelCompaniesConnectorsConditionInput
  ) {
    deleteCompaniesConnectors(input: $input, condition: $condition) {
      id
      connectorsId
      companiesId
      connectors {
        id
        logo
        name
        url
        category
        status
        description
        details
        slug
        frame
        inputs
        meta
        createdAt
        updatedAt
        __typename
      }
      companies {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConsumersCompanies = /* GraphQL */ `
  mutation CreateConsumersCompanies(
    $input: CreateConsumersCompaniesInput!
    $condition: ModelConsumersCompaniesConditionInput
  ) {
    createConsumersCompanies(input: $input, condition: $condition) {
      id
      companiesId
      consumersId
      companies {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      consumers {
        id
        meta
        companyID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyID
      __typename
    }
  }
`;
export const updateConsumersCompanies = /* GraphQL */ `
  mutation UpdateConsumersCompanies(
    $input: UpdateConsumersCompaniesInput!
    $condition: ModelConsumersCompaniesConditionInput
  ) {
    updateConsumersCompanies(input: $input, condition: $condition) {
      id
      companiesId
      consumersId
      companies {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      consumers {
        id
        meta
        companyID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyID
      __typename
    }
  }
`;
export const deleteConsumersCompanies = /* GraphQL */ `
  mutation DeleteConsumersCompanies(
    $input: DeleteConsumersCompaniesInput!
    $condition: ModelConsumersCompaniesConditionInput
  ) {
    deleteConsumersCompanies(input: $input, condition: $condition) {
      id
      companiesId
      consumersId
      companies {
        id
        name
        address
        city
        state
        postal_code
        primary_phone
        primary_email
        logo
        npi
        tax_id
        createdAt
        updatedAt
        __typename
      }
      consumers {
        id
        meta
        companyID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyID
      __typename
    }
  }
`;
export const createContactLists = /* GraphQL */ `
  mutation CreateContactLists(
    $input: CreateContactListsInput!
    $condition: ModelContactListsConditionInput
  ) {
    createContactLists(input: $input, condition: $condition) {
      id
      crmContactId
      crmListId
      crmContact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      crmList {
        id
        type
        date
        notes
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactLists = /* GraphQL */ `
  mutation UpdateContactLists(
    $input: UpdateContactListsInput!
    $condition: ModelContactListsConditionInput
  ) {
    updateContactLists(input: $input, condition: $condition) {
      id
      crmContactId
      crmListId
      crmContact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      crmList {
        id
        type
        date
        notes
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactLists = /* GraphQL */ `
  mutation DeleteContactLists(
    $input: DeleteContactListsInput!
    $condition: ModelContactListsConditionInput
  ) {
    deleteContactLists(input: $input, condition: $condition) {
      id
      crmContactId
      crmListId
      crmContact {
        id
        firstName
        lastName
        email
        phone
        isCompany
        meta
        notes
        companyID
        createdAt
        updatedAt
        __typename
      }
      crmList {
        id
        type
        date
        notes
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
