import { Input, Select, Text } from "@chakra-ui/react";
import React from "react";

export default function ValidSelect({
  label,
  value,
  placeholder,
  description,
  options,
  onChange = () => {},
}) {
  return (
    <div
      style={{
        paddingBottom: 0,
        fontSize: 12,
        fontWeight: 500,
      }}
    >
      <Text fontSize="md" fontWeight="bold" me="10px" minWidth={100}>
        {label && label + ": "}
      </Text>
      {description && (
        <Text fontSize="sm" fontWeight="400" color="gray.600">
          {description}
        </Text>
      )}
      <Select
        style={{
          backgroundColor: "white",
        }}
        placeholder={placeholder ? placeholder : "Select option"}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
      >
        {options.map((o) => (
          <option value={o.value}>{o.label}</option>
        ))}
      </Select>
    </div>
  );
}
