// Chakra imports
import { Icon } from "@aws-amplify/ui-react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import _, { map } from "lodash";
import React from "react";
import { BsInfoCircle, BsInfoCircleFill, BsQuestionCircleFill, BsXCircle, BsXCircleFill } from "react-icons/bs";

const ActivityBenefit = ({ detail }) => {

  const [show, setShow] = React.useState(false);

  const textColor = useColorModeValue("gray.700", "white");
  return (
    <>
      <Tr onClick={() => {
        setShow(!show)
      }}>
        <Td>
          {detail.benefit_coverage_code == "1" && <CheckCircleIcon color="green.500" />}
          {detail.benefit_coverage_code == "I" && <BsXCircleFill style={{ color: "red" }} />}
          {detail.benefit_coverage_code != "1" && detail.benefit_coverage_code != "I" && <BsQuestionCircleFill style={{ color: "grey" }} />}
        </Td>
        <Td>
          <b>{detail.benefit_code}</b> - {detail?.benefit_description}
          <div style={{ fontSize: "small" }}>{detail?.benefit_coverage_description}</div>
        </Td>

      </Tr>
      {show && (
        <Tr>
          <Td colSpan={2}>
            {_.map(detail, (value, key) => {
              return <div><b>{_.capitalize(key).replace("_", " ")}</b>: {value}</div>
            })}

          </Td>
        </Tr>)}
    </>
  );
};

export default ActivityBenefit;
