// Chakra imports
import {
  Avatar,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import React, { useEffect } from "react";
import ActivityBenefit from "./ActivityBenefit";

const ActivityDetails = ({ details }) => {
  const [list, setList] = React.useState([]);

  useEffect(() => {
    if (details) {
      let x = JSON.parse(JSON.parse(details));
      if (x.elig) {
        console.log("details", x.elig.benefit);
        if (x.elig.benefit) x = x.elig.benefit
        setList(x)
      }
    };
  }, [])


  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {list && list.length > 0 ? "Details" : "No Details"}
          </Text>
        </Flex>
      </CardHeader>
      {list && list.length > 0 && (
        <CardBody>
          <div>
            <div>

              <Table variant='simple' color={textColor}>
                {list.map((item) => {
                  return <ActivityBenefit
                    detail={item}
                  />
                })}
              </Table>
            </div>
          </div>
        </CardBody>
      )}
    </Card>
  );
};

export default ActivityDetails;
