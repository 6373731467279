// Chakra imports
import { Flex, Modal, Button, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useEffect } from "react";
import { tablesTableData, dashboardTableData } from "variables/general";
import { set } from "lodash";
import API from "services/API";
import Fn from "services/Fn";
import ListCompanies from "./components/ListCompanies";



function AdminCompanies() {

  const [companies, setCompanies] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    Fn.InternalController("GetCompanies", {}).then((res2) => {
      console.log("companies", res2);
      setCompanies(res2);
      setLoading(false);
    })

  }, []);


  return (
    <>
      <Flex direction='column' pt={{ base: "75px", md: "15px" }}>
        {!loading && companies && (
          <ListCompanies
            title={"Companies"}
            captions={["Company", "Action"]}
            data={companies}
          />)}
        <div style={{
          marginTop: 20
        }}>
        </div>
      </Flex>
    </>
  );

}

export default AdminCompanies;
