// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

function SignUp() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [remember, setRemember] = React.useState(false);
  const [name, setName] = React.useState("");
  const [signUpSuccess, setSignUpSuccess] = React.useState(false);

  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");


  function sumbit() {

  }

  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Box
        position='absolute'
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 20px)" }}
        borderRadius={{ md: "15px" }}
        left='0'
        right='0'
        bgRepeat='no-repeat'
        overflow='hidden'
        zIndex='-1'
        top='0'
        bgImage={BgSignUp}
        bgSize='cover'
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}></Box>
      <Flex
        direction='column'
        textAlign='center'
        justifyContent='center'
        align='center'
        mt='6.5rem'
        mb='30px'>
        <Text fontSize='4xl' color='white' fontWeight='bold'>
          Welcome!
        </Text>

      </Flex>
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='15px'
          p='40px'
          mx={{ base: "10px" }}
          bg={bgColor}
          boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
          {!signUpSuccess ? (
            <>   <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              Sign Up
            </Text>

              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Name
                </FormLabel>
                <Input
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  fontSize='sm'
                  ms='4px'
                  borderRadius='15px'
                  type='text'
                  placeholder='Your full name'
                  mb='24px'
                  size='lg'
                />
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Email
                </FormLabel>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  fontSize='sm'
                  ms='4px'
                  borderRadius='15px'
                  type='email'
                  placeholder='Your email address'
                  mb='24px'
                  size='lg'
                />
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Password
                </FormLabel>
                <Input
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}

                  fontSize='sm'
                  ms='4px'
                  borderRadius='15px'
                  type='password'
                  placeholder='Your password'
                  mb='24px'
                  size='lg'
                />
                <FormControl display='flex' alignItems='center' mb='24px'>
                  <Switch id='remember-login' colorScheme='teal' me='10px' />
                  <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal'>
                    Remember me
                  </FormLabel>
                </FormControl>
                <Button
                  disabled={email === "" || password === "" || name === ""}
                  type='submit'
                  bg='teal.300'
                  fontSize='10px'
                  color='white'
                  fontWeight='bold'
                  w='100%'
                  h='45'
                  mb='24px'
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                  onClick={() => {
                    sumbit();
                  }}
                >
                  SIGN UP
                </Button>
              </FormControl>
              <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                maxW='100%'
                mt='0px'>
                <Text color={textColor} fontWeight='medium'>
                  Already have an account?
                  <NavLink
                    style={{ color: titleColor }}
                    as='span'
                    ms='5px'
                    to='/auth/signin'
                    fontWeight='bold'

                  >
                    &nbsp; Sign In
                  </NavLink>
                </Text>
              </Flex></>) : (
            <>
              <Text
                fontSize='xl'
                color={textColor}
                fontWeight='bold'
                textAlign='center'
                mb='22px'>
                Sign Up Success! 🎉
              </Text>
              <Text
                fontSize='md'
                color={textColor}
                fontWeight='normal'
                textAlign='center'
                mb='22px'>
                Check your email to verify your account
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
