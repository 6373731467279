import { DataStore } from "@aws-amplify/datastore";
import { Amplify, API as AWSAPI, Storage } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import React from "react";
import { addMockingData as query_addMockingData, listActivities, listConnectors, listPayers, listUsers } from "../graphql/queries";
import { addMockingData as mutation_addMockingData, InternalController as mutation_InternalController } from "graphql/mutations";





const Store = {
    async image(fileName, file) {
        return await Storage.put(fileName, file).then((res) => {
            return res;
        });
    },
    imageUrl(fileName) {
        return Storage.list(fileName).then((res) => {
            console.log("list res", res);
            if (res.results.length > 0) {
                return Storage.get(fileName).then((res) => {
                    return res;
                });
            } else {
                return null;
            }
        });
    }
}

export default Store;